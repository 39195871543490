import { Box, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import customerActions from "../../redux/customer/actions";
import ReportTable from "../../common/ReportTable";

export default function Index() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const { genderCategeoryRept } = useSelector((state) => state.customerReducer);

  console.log("genderCategeoryRept", genderCategeoryRept);

  useEffect(() => {
    dispatch({ type: customerActions.GET_GENDERCATEGEORY_REPORT });
  }, [dispatch]);

  useEffect(() => {
    if (genderCategeoryRept) {
      setCategories(Object.keys(genderCategeoryRept));
    }
  }, [genderCategeoryRept]);

  const truncateCategoryName = (categoryName) => {
    if (categoryName.length > 4) {
      return (
        <>
          {categoryName.slice(0, 4)}
          <br />
          {categoryName.slice(4)}
        </>
      );
    }
    return categoryName;
  };

  const applicationStatusColumns = [
    {
      name: "Gender Vs Category or Reservation",
      dataIndex: "gender",
    },
    ...categories.map((category) => ({
      name: truncateCategoryName(category),
      dataIndex: category,
    })),
    {
      name: "Total",
      dataIndex: "total",
    },
  ];

  const applicationStatusData = ["female", "male", "other"].map((gender) => {
    const dataRow = {
      gender: gender.charAt(0).toUpperCase() + gender.slice(1),
    };
    let genderTotal = 0;

    categories.forEach((category) => {
      const categoryData = genderCategeoryRept[category] || {};
      const count = categoryData[gender] || 0;
      dataRow[category] = count;
      genderTotal += count;
    });

    dataRow.total = genderTotal;
    return dataRow;
  });

  const totalData = {
    gender: "Total",
    ...categories.reduce((acc, category) => {
      const categoryData = genderCategeoryRept[category] || {};
      acc[category] = categoryData.total || 0;
      return acc;
    }, {}),
    total: Object.values(genderCategeoryRept).reduce(
      (sum, category) => sum + (category.total || 0),
      0
    ),
  };

  console.log("Categories:", categories);
  console.log("Gender Category Report:", genderCategeoryRept);
  console.log("Application Status Data:", applicationStatusData);

  return (
    <Box
      sx={{
        margin: "1rem auto",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <Card sx={{ p: 2 }}>
        <Typography>Gender Vs Category or Reservation Report</Typography>
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "black",
              fontSize: "20px",
              lineHeight: "24px",
              mb: 1,
              backgroundColor: "whitesmoke",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            Gender Vs Category or Reservation wise Report{" "}
          </Typography>
        </Box>
        <ReportTable
          columnData={applicationStatusColumns}
          tableData={applicationStatusData}
          totalData={totalData}
        />
      </Card>
    </Box>
  );
}

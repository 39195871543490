import { put, call, all, takeEvery, delay, select } from "redux-saga/effects";
import candidateActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";


const candidateSagas = function* () {
  yield all([
    yield takeEvery(
      candidateActions.GET_FULL_CANDIDATE_DETAILS,
      getFullCandidateDetails
    ),
    yield takeEvery(
      candidateActions.GET_TICKETS_LIST_BY_CANDIDATE,
      getTicketsListByCandidate
    ),
    yield takeEvery(
      candidateActions.GET_UPLOAD_PERSONAL_DETAILS,
      getUploadDetails
    ),
    yield takeEvery(candidateActions.GET_SEARCH_CANDIDATE_DETAILS, getCandidateDetailsBySearch),
    yield takeEvery(candidateActions.CHECK_DUPLICATE_EMAIL_OR_MOBILENO,checkDuplicateEmailOrMobileno),
    yield takeEvery(candidateActions.GET_PROFILE_PERSONAL_DETAILS,getProfilePersonalDetails),
    yield takeEvery(candidateActions.UPDATE_CANDIDATE_DETAILS,updateCandidateDetails)
  ]);
};

const getFullCandidateDetails = function* (data) {
  const { payload } = data;
  console.log(payload,'ffffff');

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/candidate/details/${payload?.id}`)
    );
    yield put({
      type: candidateActions.SET_FULL_CANDIDATE_DETAILS,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const getTicketsListByCandidate = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/grievance/ticket/candidate/${payload?.id}`
      )
    );
    yield put({
      type: candidateActions.SET_TICKETS_LIST_BY_CANDIDATE,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
  } catch (err) {
    console.log(err);
  }
};

const getUploadDetails = function* (data) {
  const { payload } = data;
  console.log(payload,'ffffff');
  

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/uploads/${payload?.id}`)
    );
    if (result) {
      yield put({
        type: candidateActions.SET_UPLOAD_PERSONAL_DETAILS,
        payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
      });
    }
    console.log(result);
  } catch (err) {
    console.log(err);
  }
};

const getCandidateDetailsBySearch = function* (data) {
  const { payload } = data;
  try{
    const result = yield call(() => axios.get(`${API_URL}/backoffice/candidatemanagement`,{
      params:{
        candidatename: payload?.candidatename,
        emailid: payload?.emailid,
        mobileno:payload?.mobileno,
        dob:payload?.dob,
        candidateid:payload?.data?.candidateid
      }}
    ));
    if(result){
      let arr = result?.data?.result;
      let finalarr = arr?.map((val,i) =>(
        {...val,
        's.no': i+1
      }))
      yield put({
        type: payload?.data?.candidateid ? candidateActions.SET_UPDATE_CANDIDATE_DETAILS : candidateActions.SET_SEARCH_CANDIDATE_DETAILS,
        payload: result?.data?.statusCode === 200 ? finalarr : []
      })
    }
  }catch(err){
    console.log(err);
  }
}

const checkDuplicateEmailOrMobileno = function* (data) {
  const { payload } = data;
  const { setAndClearError } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-duplicate`, payload?.data)
    );
    if (result?.data) {
      setAndClearError({ ...result?.data, type: payload?.data?.type });
    }
  } catch (err) {
    console.log(err);
  }
};

const getProfilePersonalDetails = function* (data) {
  const { payload } = data;
  console.log(payload,'ffffff');

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/${payload?.id}`)
    );
    if (result) {
      yield put({
        type: candidateActions.SET_PROFILE_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    console.log(result);
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const updateCandidateDetails = function* (data) {
  const { payload } = data;
  try{
    const result = yield call(() => axios.put(`${API_URL}/backoffice/candidatemanagement/${payload?.ssouserid}/${payload?.candidateid}`,{
        candidatename: payload?.candidatename,
        emailid:payload?.emailid,
        mobileno:payload?.mobileno,
        dob:payload?.dob
    }))
    if(result){
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
  }catch(err){
    console.log(err); 
  }
}

export default candidateSagas;

import {
  FormControlLabel,
  Grid,
  TextField,
  styled,
  Switch,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Typography,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BrowseUploadButton from "../../common/button/browseUploadButton";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import PrintDownloadButton from "../../common/button/printDownloadButton";
import { MdOutlineEdit } from "react-icons/md";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import BackButton from "../../common/button/backButton";
import PreRequisiteModal from "./preRequisiteModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import MasterModel from "../createMaster/masterModel";
import { useDispatch, useSelector } from "react-redux";
import masterFormsActions from "../../redux/masterForms/actions";
import masterActions from "../../redux/master/actions";
import dayjs from "dayjs";
import pdf from "../../assets/images/uploads/pdf.png";
import { PiX, PiXBold } from "react-icons/pi";
import Prerequesite from "./preRequesite";
import { IoCloudUploadOutline } from "react-icons/io5";
import { NumbersValidation } from "../../utils/validation";
import commonActions from "../../redux/common/actions";
import CreateMasterModal from "../masterForm/modals/createMasterModal";

export default function AddCourse() {
  const navigate = useNavigate();
  const location = useLocation();
  let id = location?.state?.id;
  const dispatch = useDispatch();
  const today = new Date();

  const {
    driveList,
    stateList,
    districtList,
    graduationBranchList,
    awardedClassList,
    examLanguageList,
  } = useSelector((state) => state.masterFormsReducer);
  const { courseList } = useSelector((state) => state.masterReducer);
  const { courseCategoryList } = useSelector(
    (state) => state.masterFormsReducer
  );
  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);
  const [modelOpen, setModelOpen] = useState({
    show: false,
    type: "",
  });
  const [documentError, setDocumentError] = useState({
    error: false,
    message: "",
  });
  const [document, setDocument] = useState({
    name: "",
    file: null,
  });
  // console.log("lists---", graduationBranchList,awardedClassList);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    control,
    setError,
    resetField,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch({ type: masterFormsActions.GET_DRIVE });
    dispatch({ type: masterFormsActions.GET_STATE });
    dispatch({ type: masterFormsActions.GET_DISTRICT });
    dispatch({ type: masterFormsActions.GET_COURSECATEGORY });
    dispatch({ type: masterFormsActions.GET_GRADUATION_BRANCH });
    dispatch({ type: masterFormsActions.GET_AWARDED_CLASS });
    dispatch({ type: masterFormsActions.GET_EXAM_LANGUAGE });
    if (location.search === "?page=updatecourse") {
      dispatch({
        type: masterActions.GET_COURSE_LIST,
      });
    }
  }, []);
  useEffect(() => {
    console.log(watch("preferencestateid"), "watch");
    let states = watch("preferencestateid");
    if (states.length > 0) {
      let filtered = districtList?.all?.filter((obj) =>
        states?.map((id) => parseInt(id)).includes(parseInt(obj.stateid))
      );
      console.log(filtered.length, districtList?.all?.length, "length");
      dispatch({
        type: masterFormsActions.SET_DISTRICT,
        payload: { ...districtList, filtered: filtered },
      });
    } else {
      // console.log('in else ',districtList)
      dispatch({
        type: masterFormsActions.SET_DISTRICT,
        payload: { ...districtList, filtered: [] },
      });
    }
  }, [watch("preferencestateid")]);
  useEffect(() => {
    if (id) {
      let { prerequisite, ...course } =
        courseList?.all?.find((obj) => obj.courseid === id) || {};
      console.log(course, "crse");
      // let { regenddate, regstartdate, ...course } = fileterd

      course["regstartdate"] = dayjs(course.regstartdate);
      course["regenddate"] = dayjs(course.regenddate);
      course["latefeestartdate"] = dayjs(course.latefeestartdate);
      course["latefeeenddate"] = dayjs(course.latefeeenddate);
      course["editingstartdate"] = dayjs(course.editingstartdate);
      course["editingenddate"] = dayjs(course.editingenddate);
      course["editingextrastartdate"] = dayjs(course.editingextrastartdate);
      course["editingextraenddate"] = dayjs(course.editingextraenddate);
      course["coolingstartdate"] = dayjs(course.coolingstartdate);
      course["coolingenddate"] = dayjs(course.coolingenddate);

      if (course?.preferencestateid?.length > 0) {
        let filtered = districtList?.all?.filter((obj) =>
          course?.preferencestateid
            ?.map((id) => parseInt(id))
            .includes(parseInt(obj.stateid))
        );
        // console.log(filtered.length,districtList?.all?.length,"length");
        dispatch({
          type: masterFormsActions.SET_DISTRICT,
          payload: { ...districtList, filtered: filtered },
        });
      } else {
        // console.log('in else ',districtList)
        dispatch({
          type: masterFormsActions.SET_DISTRICT,
          payload: { ...districtList, filtered: [] },
        });
      }

      course["brochurelocation"]?.trim() !== "" &&
        setDocument({
          name: course["brochurelocation"],
          file: null,
        });
      setPreRequisiteContent(prerequisite);
      for (let key in course) {
        setValue(key, course[key]);
      }
      console.log(course);
    }
  }, [setValue, courseList, id]);

  const handleModelOpen = (type) => {
    setModelOpen({
      show: true,
      type: type,
    });
  };
  const [preRequisiteContent, setPreRequisiteContent] = useState("");

  const [showPreRequisite, setShowPreRequisite] = useState({
    show: false,
    data: null,
  });
  const resetForm = () => {
    setValue("coursedriveid", "");
    setValue("coursecategoryid", "");
    setDocument({ name: "", file: null });
    reset({
      coursedriveid: "", // replace with default values for all fields
      coursecategoryid: "",
      coursename: "",
      coursecode: "",
      regstartdate: null, // or the default value for date pickers
      regenddate: null, // or the default value for date pickers
      // informationbrochure: "",
      status: true, // default value for switches or checkboxes
      // Add other fields as needed
    });
  };
  const handleClose = () => {
    setShowPreRequisite({ show: false, data: null });
  };

  const handlePreRequisite = () => {
    setShowPreRequisite({ show: true, data: null });
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const handleUpload = (e) => {
    const file = e.target.files || e.dataTransfer.files;
    // Update form state using React Hook Form methods
    setValue("document", file[0]);
    setDocument({
      name: file[0].name,
      file: file[0],
    });
    setDocumentError({ error: false, message: "" });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy"; // Show the copy cursor when dragging
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleUpload(e);
  };

  const handleAddDrive = () => {
    let value = getValues("name");
    if (value.trim() === "") {
      trigger("name");
      return;
    }
    let data = {};
    data["drivename"] = value;
    // data['status'] = "1";
    data["userid"] = backofficeTokenDetails?.id;
    setModelOpen({
      show: false,
      type: "",
    });

    dispatch({
      type: masterFormsActions.CREATE_DRIVE,
      payload: { data: data },
    });
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: {
        show: false,
        type: null,
        method: null,
        data: null,
      },
    });
    dispatch({ type: masterFormsActions.GET_DRIVE });
  };
  const handleAddCourse = (data) => {
    console.log(data, "data");
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const regStartDate = new Date(data.regstartdate);
    const regStartDateWithoutTime = new Date(
      regStartDate.getFullYear(),
      regStartDate.getMonth(),
      regStartDate.getDate()
    );

    if (regStartDateWithoutTime.getTime() < todayWithoutTime.getTime()) {
      setError("regstartdate", {
        type: "custom",
        message: "please select a future date",
      });
      return;
    }

    if (!isDateGreaterThanDay(data.regenddate, new Date(data.regstartdate))) {
      setError("regenddate", {
        type: "custom",
        message: "please select a date greater than start date",
      });
      return;
    }
    if (document?.name !== "") {
      setDocumentError({ error: false, message: "" });

      console.log("submitted", location.search);
      data["prerequisite"] = preRequisiteContent;
      data["userid"] = backofficeTokenDetails?.id;
      data["regstartdate"] = formatDate(data.regstartdate);
      data["regenddate"] = formatDate(data.regenddate);
      data["latefeestartdate"] = formatDate(data.latefeestartdate);
      data["latefeeenddate"] = formatDate(data.latefeeenddate);
      data["editingstartdate"] = formatDate(data.editingstartdate);
      data["editingenddate"] = formatDate(data.editingenddate);
      data["editingextrastartdate"] = formatDate(data.editingextrastartdate);
      data["editingextraenddate"] = formatDate(data.editingextraenddate);
      data["coolingstartdate"] = formatDate(data.coolingstartdate);
      data["coolingenddate"] = formatDate(data.coolingenddate);

      data["coursecategoryid"] = parseInt(data.coursecategoryid);
      data["coursedriveid"] = parseInt(data.coursedriveid);
      data["document"] = document;

      console.log(data, "data");
      dispatch({
        type: masterActions.CREATE_COURSE,
        payload: { data: data, navigate: navigate, resetForm: resetForm },
      });
    } else {
      setDocumentError({ error: true, message: "Please upload Document" });
    }
  };
  const handleUpdateCourse = (data) => {
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const regStartDate = new Date(data.regstartdate);
    const regStartDateWithoutTime = new Date(
      regStartDate.getFullYear(),
      regStartDate.getMonth(),
      regStartDate.getDate()
    );

    // if (regStartDateWithoutTime.getTime() < todayWithoutTime.getTime()) {
    //   setError("regstartdate", {
    //     type: "custom",
    //     message: "please select a future date",
    //   });
    //   return;
    // }

    if (!isDateGreaterThanDay(data.regenddate, new Date(data.regstartdate))) {
      setError("regenddate", {
        type: "custom",
        message: "please select a date greater than start date",
      });
      return;
    }
    if (document) {
      setDocumentError({ error: false, message: "" });
      console.log("submitted", data);
      data["prerequisite"] = preRequisiteContent;
      data["userid"] = backofficeTokenDetails?.id;
      data["regstartdate"] = formatDate(data.regstartdate);
      data["regenddate"] = formatDate(data.regenddate);
      data["latefeestartdate"] = formatDate(data.latefeestartdate);
      data["latefeeenddate"] = formatDate(data.latefeeenddate);
      data["editingstartdate"] = formatDate(data.editingstartdate);
      data["editingenddate"] = formatDate(data.editingenddate);
      data["editingextrastartdate"] = formatDate(data.editingextrastartdate);
      data["editingextraenddate"] = formatDate(data.editingextraenddate);
      data["coolingstartdate"] = formatDate(data.coolingstartdate);
      data["coolingenddate"] = formatDate(data.coolingenddate);
      data["coursecategoryid"] = parseInt(data.coursecategoryid);
      data["coursedriveid"] = parseInt(data.coursedriveid);
      data["document"] = document;

      dispatch({
        type: masterActions.UPDATE_COURSE,
        payload: { data: data, navigate: navigate, resetForm: resetForm },
      });
    } else {
      setDocumentError({ error: true, message: "Please upload Document" });
    }
  };
  function isDateGreaterThanDay(dateString, today) {
    const dateToCompare = new Date(dateString);

    return dateToCompare >= today;
  }

  const formatDate = (date) => {
    // Assuming date is a Date object or ISO string, convert to desired format
    const formattedDate = new Date(date)
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-")
      .split("-")
      .reverse()
      .join("-");

    return date && formattedDate !== "Invalid Date" ? formattedDate : ""; // returns something like '01-07-2024'
  };
  const handleCloseDocument = () => {
    setDocument({
      name: "",
      file: null,
    });
  };

  return (
    <>
      <Typography variant="h5" mb={3}>
        Course Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl error={!!errors.coursedriveid} fullWidth>
              <InputLabel htmlFor="coursedriveid">
                <span>
                  Select Course Drive
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              </InputLabel>
              <Controller
                name="coursedriveid"
                control={control}
                defaultValue="" // Initial value if any
                rules={{ required: "Drive Name is required" }} // Validation rule
                helperText={errors?.coursedriveid?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                error={!!errors.coursedriveid}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={
                      <span>
                        Select Course Drive
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </span>
                    }
                    labelId="coursedriveid"
                    id="coursedriveid"
                    error={!!errors.coursedriveid}
                    helperText={errors.coursedriveid?.message}
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select Drive Name
                    </MenuItem>
                    {driveList?.all.map((obj, i) => (
                      <MenuItem
                        key={obj.driveid.toString()}
                        value={obj.driveid.toString()}
                      >
                        {obj.drivename}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {/* {errors?.coursedriveid?.message} */}
            </FormControl>

            <Button
              variant="outlined"
              onClick={() => {
                dispatch({
                  type: commonActions.SET_SHOW_MODAL,
                  payload: {
                    show: true,
                    type: "drive",
                    method: "C",
                    data: null,
                  },
                });
              }}
              sx={{
                color: "black",
                borderColor: "#A3A3A3",
                padding: 1,
                minWidth: "0",
              }}
            >
              <FaPlus />
            </Button>
          </Box>
          <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
            {errors?.coursedriveid?.message}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl error={!!errors.coursecategoryid} fullWidth>
            <InputLabel htmlFor="coursecategoryid">
              <span>
                Select Course Category
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            </InputLabel>
            <Controller
              name="coursecategoryid"
              id="demo-simple-select"
              helperText={errors?.coursecategoryid?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              error={!!errors.coursecategoryid}
              rules={{ required: "Course Category is required" }}
              control={control}
              defaultValue="" // Initial value if any
              render={({ field }) => (
                <Select
                  size="small"
                  fullWidth
                  label={
                    <span>
                      Select Course Category
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </span>
                  }
                  {...field}
                  error={!!errors.coursecategoryid}
                  helperText={errors.coursecategoryid?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}

                  // defluaultValue=""
                  // placeholder="Select Course Category"
                >
                  <MenuItem key="" value="" disabled>
                    Select Course Category
                  </MenuItem>

                  {courseCategoryList &&
                    courseCategoryList.all.map((obj) => {
                      return (
                        <MenuItem
                          key={obj.coursecategoryid.toString()}
                          value={obj.coursecategoryid.toString()}
                        >
                          {obj.coursecategory}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
            />
          </FormControl>
          <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
            {errors?.coursecategoryid?.message}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("coursename", {
              required: "Course Name is required",
            })}
            InputLabelProps={{
              shrink: !!watch("coursename"),
            }}
            error={!!errors.coursename}
            helperText={errors.coursename?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Course Name
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl error={!!errors.coursecategoryid} fullWidth>
            <InputLabel htmlFor="coursecategoryid">
              <span>
                Select Exam Language
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            </InputLabel>
            <Controller
              name="examlanguageid"
              control={control}
              rules={{ required: "Exam Language is required" }}
              defaultValue={[]} // Initial value if any
              render={({ field }) => (
                <Select
                  size="small"
                  fullWidth
                  multiple
                  value={field.value}
                  onChange={(e) => {
                    const selectedValues = e.target.value;

                    const validValues = selectedValues.filter(
                      (val) => val !== null && val !== undefined
                    );

                    const uniqueValues = Array.from(
                      new Set(validValues.map((val) => val?.toString()))
                    );

                    field.onChange(uniqueValues);
                  }}
                  label={
                    <span>
                      Select Exam Language
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </span>
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const selectedLanguage = examLanguageList?.all?.find(
                          (lang) =>
                            parseInt(lang.examlanguageid) === parseInt(value)
                        );
                        return (
                          <Chip
                            key={value}
                            label={selectedLanguage?.languagename || "Unknown"}
                          />
                        );
                      })}
                    </Box>
                  )}
                  error={!!errors.examlanguageid}
                >
                  <MenuItem key="" value="" disabled>
                    Select Exam Language
                  </MenuItem>

                  {examLanguageList &&
                    examLanguageList.all.map((obj) => (
                      <MenuItem
                        key={obj.examlanguageid?.toString()}
                        value={obj.examlanguageid?.toString()}
                      >
                        {obj.languagename}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
          <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
            {errors?.examlanguageid?.message}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("coursecode", {
              required: "Course Code is required",
              validate: (value) => {
                return value
                  ? /^\d+$/.test(value) || "Course Code must be numeric"
                  : true;
              },
            })}
            InputLabelProps={{
              shrink: !!watch("coursecode"),
            }}
            error={!!errors.coursecode}
            helperText={errors.coursecode?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Course Code
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
            onChange={(e) => {
              const value = e.target.value;
              setValue("coursecode", value);
              trigger("coursecode");
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="h5" mb={3}>
        Qualification Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl error={!!errors.graduationbranchid} fullWidth>
              <InputLabel htmlFor="graduationbranchid">
                <span>Select Graduation Branches</span>
              </InputLabel>
              <Controller
                name="graduationbranchid"
                control={control}
                defaultValue={[]} // Initial value if any
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    value={field.value}
                    onChange={(e) => {
                      const selectedValues = e.target.value;

                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );

                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      field.onChange(uniqueValues);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedBranch =
                            graduationBranchList?.all?.find(
                              (lang) =>
                                parseInt(lang.graduationbranchid) ===
                                parseInt(value)
                            );
                          return (
                            <Chip
                              key={value}
                              label={
                                selectedBranch?.graduationbranch || "Unknown"
                              }
                            />
                          );
                        })}
                      </Box>
                    )}
                    label={<span>Select Graduation Branches</span>}
                    labelId="graduationbranchid"
                    id="graduationbranchid"
                    error={!!errors.graduationbranchid}
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select Graduation Branches
                    </MenuItem>
                    {graduationBranchList?.all.map((obj) => (
                      <MenuItem
                        key={obj.graduationbranchid?.toString()}
                        value={obj.graduationbranchid?.toString()}
                      >
                        {obj.graduationbranch}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
            {errors?.graduationbranchid?.message}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl error={!!errors.awardedclassid} fullWidth>
              <InputLabel htmlFor="awardedclassid">
                <span>Select Awarded Class</span>
              </InputLabel>
              <Controller
                name="awardedclassid"
                control={control}
                defaultValue={[]} // Initial value if any
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    value={field.value}
                    onChange={(e) => {
                      const selectedValues = e.target.value;

                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );

                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      field.onChange(uniqueValues);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedClass = awardedClassList?.all?.find(
                            (lang) =>
                              parseInt(lang.awardedclassid) === parseInt(value)
                          );
                          return (
                            <Chip
                              key={value}
                              label={selectedClass?.awardedclass || "Unknown"}
                            />
                          );
                        })}
                      </Box>
                    )}
                    label={<span>Select Awarded Class</span>}
                    labelId="awardedclassid"
                    id="awardedclassid"
                    error={!!errors.awardedclassid}
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select Awarded Class
                    </MenuItem>
                    {awardedClassList?.all.map((obj) => (
                      <MenuItem
                        key={obj.awardedclassid?.toString()}
                        value={obj.awardedclassid?.toString()}
                      >
                        {obj.awardedclass}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
            {errors?.awardedclassid?.message}
          </FormHelperText>
        </Grid>
      </Grid>
      <Typography variant="h5" mb={3}>
        Preference Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl error={!!errors.preferencestateid} fullWidth>
              <InputLabel htmlFor="preferencestateid">
                <span>
                  Select Preference States
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              </InputLabel>
              <Controller
                name="preferencestateid"
                control={control}
                defaultValue={[]}
                rules={{ required: "Preference States is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    value={field.value}
                    onChange={(e) => {
                      const selectedValues = e.target.value;
                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );
                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      field.onChange(uniqueValues);

                      if (uniqueValues.length === 0) {
                        // Reset districts when no states are selected
                        resetField("preferencedistrictid");
                      } else {
                        // Optional: Update districts based on the selected states
                        // (Implement your logic to filter districts based on states)
                      }
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedState = stateList?.all?.find(
                            (lang) => parseInt(lang.stateid) === parseInt(value)
                          );
                          return (
                            selectedState && (
                              <Chip key={value} label={selectedState.state} />
                            )
                          );
                        })}
                      </Box>
                    )}
                    label={
                      <span>
                        Select Preference States
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </span>
                    }
                    labelId="preferencestateid"
                    id="preferencestateid"
                    error={!!errors.preferencestateid}
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select States
                    </MenuItem>
                    {stateList?.all.map((obj) => (
                      <MenuItem
                        key={obj.stateid?.toString()}
                        value={obj.stateid?.toString()}
                      >
                        {obj.state}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
              {errors?.preferencestateid?.message}
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl error={!!errors.preferencedistrictid} fullWidth>
              <InputLabel htmlFor="preferencedistrictid">
                <span>
                  Select Preference Districts
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              </InputLabel>
              <Controller
                name="preferencedistrictid"
                control={control}
                defaultValue={[]} // Initial value if any
                rules={{ required: "Preference Districts is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    value={field.value}
                    onChange={(e) => {
                      const selectedValues = e.target.value;
                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );
                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      if (uniqueValues.length === 0) {
                        // Reset districts when no states are selected
                        resetField("preferencedistrictid");
                      }

                      field.onChange(uniqueValues);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedDistrict = districtList?.filtered?.find(
                            (lang) =>
                              parseInt(lang.districtid) === parseInt(value)
                          );
                          return (
                            selectedDistrict && (
                              <Chip
                                key={value}
                                label={selectedDistrict.district}
                              />
                            )
                          );
                        })}
                      </Box>
                    )}
                    label={
                      <span>
                        Select Preference Districts
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </span>
                    }
                    labelId="preferencedistrictid"
                    id="preferencedistrictid"
                    error={!!errors.preferencedistrictid}
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select Districts
                    </MenuItem>
                    {districtList?.filtered.map((obj) => (
                      <MenuItem
                        key={obj.districtid.toString()}
                        value={obj.districtid.toString()}
                      >
                        {obj.district}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
              {errors?.preferencedistrictid?.message}
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h5" mb={3}>
        Date Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          {/* <Controller
            name="regstartdate"
            control={control}
            rules={{
              required: "Registration start date is required",
              validate: {
                validDate: (date) => {
                  const isValid =
                    date &&
                    (dayjs(date).isSame(dayjs(), "day") ||
                      dayjs(date).isAfter(dayjs(), "day"));
                  return isValid || "Date must be a today or later";
                },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Registration Start Date
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  format="DD/MM/YYYY"
                  disablePast
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.regstartdate,
                      helperText: errors.regstartdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          /> */}
          <Controller
            name="regstartdate"
            control={control}
            rules={{
              required: "Registration start date is required",
              validate: {
                validDate: (date) => {
                  if (location.search === "?page=updatecourse") {
                    return true;
                  }

                  const isValid =
                    date &&
                    (dayjs(date).isSame(dayjs(), "day") ||
                      dayjs(date).isAfter(dayjs(), "day"));
                  return isValid || "Date must be today or later";
                },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Registration Start Date
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  format="DD/MM/YYYY"
                  disablePast
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.regstartdate,
                      helperText: errors.regstartdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="regenddate"
            control={control}
            rules={{
              required: "Registration end date is required",
              validate: {
                validDate: (date) => {
                  const isValid =
                    date &&
                    (dayjs(date).isSame(dayjs(), "day") ||
                      dayjs(date).isAfter(dayjs(), "day"));
                  return isValid || "Date must be a today or later";
                },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Registration End Date
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("regstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.regenddate,
                      helperText: errors.regenddate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="latefeestartdate"
            control={control}
            rules={{
              // required: "Late Fee Registration start date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Late Fee Registration Start Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("regstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.latefeestartdate,
                      helperText: errors.latefeestartdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="latefeeenddate"
            control={control}
            rules={{
              // // required: "Late Fee Registration end date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Late Fee Registration End Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("latefeestartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.latefeeenddate,
                      helperText: errors.latefeeenddate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="coolingstartdate"
            control={control}
            rules={{
              // // required: "Cooling period start date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Cooling Period Start Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("regstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.coolingstartdate,
                      helperText: errors.coolingstartdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="coolingenddate"
            control={control}
            rules={{
              // // required: "Cooling period end date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Cooling Period End Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("coolingstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.coolingenddate,
                      helperText: errors.coolingenddate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="editingstartdate"
            control={control}
            rules={{
              // // required: "Editing facility start date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Editing Facility Start Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("regstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.editingstartdate,
                      helperText: errors.editingstartdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="editingenddate"
            control={control}
            rules={{
              // // required: "Editing Facility end date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Editing Facility End Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("editingstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.editingenddate,
                      helperText: errors.editingenddate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="editingextrastartdate"
            control={control}
            rules={{
              // // required: "Editing Facility extra fee end date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Editing Facility Extra Fee Start Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("regstartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.editingextrastartdate,
                      helperText: errors.editingextrastartdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="editingextraenddate"
            control={control}
            rules={{
              // // required: "Editing Facility extra fee end date is required",
              validate: {
                // validDate: (date) => {
                //   const isValid =
                //     date &&
                //     (dayjs(date).isSame(dayjs(), "day") ||
                //       dayjs(date).isAfter(dayjs(), "day"));
                //   return isValid || "Date must be a today or later";
                // },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Editing Facility Extra Fee End Date
                      {/* <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span> */}
                    </span>
                  }
                  format="DD/MM/YYYY"
                  minDate={watch("editingextrastartdate") || dayjs()}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                      error: !!errors.editingextraenddate,
                      helperText: errors.editingextraenddate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="h5" mb={3}>
        Payment Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        {/* <Grid item xs={12} md={6}>
          <TextField
            {...register("payment", {
              required: "Payment Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{}}
            error={!!errors.payment}
            helperText={errors.payment?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment Amount
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid> */}

        <Grid item xs={12} md={6}>
          <TextField
            {...register("generalamount", {
              required: "Payment for general Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{
              shrink: !!watch("generalamount"),
            }}
            error={!!errors.generalamount}
            helperText={errors.generalamount?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment For General Amount
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("nongeneralamount", {
              required: "Payment for non general Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{
              shrink: !!watch("nongeneralamount"),
            }}
            error={!!errors.nongeneralamount}
            helperText={errors.nongeneralamount?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment For Non General Amount
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("lategeneralamount", {
              // required: "payment for general late fee Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{
              shrink: !!watch("lategeneralamount"),
            }}
            error={!!errors.lategeneralamount}
            helperText={errors.lategeneralamount?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment For General Late Amount
                {/* <span className="error" style={{ color: "red" }}>
                  *
                </span> */}
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("latenongeneralamount", {
              // // required: "payment for non general late fee Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{
              shrink: !!watch("latenongeneralamount"),
            }}
            error={!!errors.latenongeneralamount}
            helperText={errors.latenongeneralamount?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment For Non General Late Amount
                {/* <span className="error" style={{ color: "red" }}>
                  *
                </span> */}
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("generalextraamount", {
              // // required: "payment for general extra fee Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{
              shrink: !!watch("generalextraamount"),
            }}
            error={!!errors.generalextraamount}
            helperText={errors.generalextraamount?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment For General Extra Fee Amount
                {/* <span className="error" style={{ color: "red" }}>
                  *
                </span> */}
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("nongeneralextraamount", {
              // // required: "payment for non general extra fee Amount is required",
            })}
            onInput={NumbersValidation}
            InputLabelProps={{
              shrink: !!watch("nongeneralextraamount"),
            }}
            error={!!errors.nongeneralextraamount}
            helperText={errors.nongeneralextraamount?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Payment For Non General Extra Fee Amount
                {/* <span className="error" style={{ color: "red" }}>
                  *
                </span> */}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Typography variant="h5" mb={3}>
        Billdesk Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("billdeskmerchantid", {
              required: "Merchant Id For Billdesk is required",
            })}
            InputLabelProps={{
              shrink: !!watch("billdeskmerchantid"),
            }}
            error={!!errors.billdeskmerchantid}
            helperText={errors.billdeskmerchantid?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Merchant Id For Billdesk
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("billdesksecurityid", {
              required: "Merchant Id For PayU is required",
            })}
            InputLabelProps={{
              shrink: !!watch("billdesksecurityid"),
            }}
            error={!!errors.billdesksecurityid}
            helperText={errors.billdesksecurityid?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Security Id For Billdesk
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("billdeskclientid", {
              required: "Merchant Id For PayU is required",
            })}
            InputLabelProps={{
              shrink: !!watch("billdeskclientid"),
            }}
            error={!!errors.billdeskclientid}
            helperText={errors.billdeskclientid?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Client Id For Billdesk
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Typography variant="h5" mb={3}>
        PayU Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("payumerchantid", {
              required: "Merchant Id For PayU is required",
            })}
            InputLabelProps={{
              shrink: !!watch("payumerchantid"),
            }}
            error={!!errors.payumerchantid}
            helperText={errors.payumerchantid?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Merchant Id For PayU
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("payusaltkey", {
              required: "Merchant Id For Billdesk is required",
            })}
            InputLabelProps={{
              shrink: !!watch("payusaltkey"),
            }}
            error={!!errors.payusaltkey}
            helperText={errors.payusaltkey?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            size="small"
            fullWidth
            label={
              <span>
                Salt Key For PayU
                <span className="error" style={{ color: "red" }}>
                  *
                </span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Typography variant="h5" mb={3}>
        Other Information
      </Typography>
      <Grid container spacing={3} mb={3}>
        {/* <Grid item xs={12} md={6}> 
       <TextField
          {...register("informationbrochure", {
            required: "Information Brochure is required",

          // })}

          // error={!!errors.informationbrochure}
          // helperText={errors.informationbrochure?.message}
          FormHelperTextProps={{
            style: { margin: 0 },
          }}
          size="small"
          fullWidth
          label={
            <span>
              Information Brochure
              <span className="error" style={{ color: "red" }}>
                *
              </span>
            </span>
          }
        /> </Grid> */}
        <Grid item xs={12}>
          {document?.name !== "" ? (
            <Box
              style={{
                width: "18em",
                height: "45px",
                border: "1px solid #D5D5D5",
                display: "flex",
                alignItems: "center",
                gap: "1%",
                // marginTop: "1rem",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <img
                src={pdf}
                style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                alt="pdf"
              />
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {document?.name}
              </span>
              <PiX
                style={{ margin: 0, padding: 0, cursor: "pointer" }}
                onClick={handleCloseDocument}
              />
            </Box>
          ) : (
            <>
              <InputLabel sx={{ mb: 1 }} htmlFor="courseFile">
                <span>
                  Upload Brochure
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              </InputLabel>
              {/* <BrowseUploadButton
            id="courseFile"
            name="courseFile"
            onChange={handleUpload}
            accept="application/pdf"
            icon={<MdOutlinePictureAsPdf />}
            title={"browse & upload"}
          /> */}

              <Box>
                {document?.name !== "" ? (
                  <Box
                    pl={2}
                    style={{
                      width: "fit-content",
                      // maxWidth: "18em",
                      height: "45px",
                      border: "1px solid #D5D5D5",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      marginTop: "1rem",
                      paddingLeft: "0",
                      // overflow: "hidden",
                      // whiteSpace: "nowrap",
                      // position: 'relative'
                    }}
                  >
                    <Box sx={{ paddingLeft: "10px" }}>{document?.name}</Box>
                    <Box
                      onClick={() => setDocument({ file: null, name: "" })}
                      sx={{ paddingRight: "10px", cursor: "pointer" }}
                      //  sx={{position: 'absolute', right: 0, width: '1.5rem', paddingLeft: '5px', backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`}}
                    >
                      <PiXBold />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    style={{
                      border: "1px dashed gray",
                      maxWidth: "330px",
                      minHeight: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexShrink: 340,
                      position: "relative",
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      name="document"
                      id="document"
                      accept=".jpeg,.jpg,.png,.gif,.pdf"
                      alt=""
                      style={{
                        width: "0.1px",
                        height: "0.1px",
                        opacity: 0,
                        overflow: "hidden",
                        position: "absolute",
                        zIndex: -1,
                      }}
                      // onChange={(e) => handleUpload(e)
                      onChange={(e) => handleUpload(e)}
                    />
                    <label
                      htmlFor="document"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IoCloudUploadOutline
                        style={{ marginLeft: "8px" }}
                        size={22}
                      />
                      <span>
                        {" "}
                        &nbsp; Drag or drop your file, or{" "}
                        <span style={{ color: "blue" }}>&nbsp;browse</span>
                      </span>
                      <small style={{ color: "rgb(102, 102, 102)" }}>
                        (JPEG, PNG, GIF and PDF) (5mb max.)
                      </small>
                    </label>
                  </Box>
                )}
                {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                }}
              >
                <small style={{ color: "rgb(102, 102, 102)" }}>
                  Supported file format: JPEG, PNG, GIF and PDF
                </small>
                <small style={{ color: "rgb(102, 102, 102)" }}>
                  Max limit 5mb
                </small>
              </Box> */}
              </Box>
            </>
          )}
          <Box sx={{ width: "100%" }}>
            {documentError.error && (
              <FormHelperText style={{ margin: 0, color: "#d32f2f" }}>
                {documentError?.message}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PrintDownloadButton
            startIcon={<MdOutlineEdit />}
            name={"PRE- REQUISITE"}
            handleSubmit={handlePreRequisite}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <CustomSwitch
                {...control}
                name="status"
                {...register("status")}
                onChange={(e) => setValue("status", e.target.checked)}
              />
            }
            label="Enable"
          />
        </Grid>
        <Grid
          item
          md={12}
          sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
        >
          <BackButton
            handleBack={() =>
              navigate(
                `/${Cookies.get(
                  "mhet_mst_project"
                )}/course-management?page=courselist`
              )
            }
          />
          <BluePrimaryButton
            handleSubmit={handleSubmit(
              location.search !== "?page=updatecourse"
                ? handleAddCourse
                : handleUpdateCourse
            )}
            title={
              location.search === "?page=updatecourse" ? "Update" : "Submit"
            }
          />
        </Grid>
      </Grid>

      <Prerequesite
        showModal={showPreRequisite?.show}
        handleClose={handleClose}
        setContent={setPreRequisiteContent}
        preRequisiteContent={preRequisiteContent}
      />
      {/* <PreRequisiteModal
        showModal={showPreRequisite?.show}
        handleClose={handleClose}
        setContent={setPreRequisiteContent}
        preRequisiteContent={preRequisiteContent}
      /> */}
      <CreateMasterModal
        // trigger={trigger}
        getValues={getValues}
        register={register}
        // handleSubmit={handleSubmit}
        errors={errors}
        handleCreate={handleAddDrive}
        resetForm={resetForm}
        watch={watch}
      />
    </>
  );
}

import React from "react";
import { useSearchParams } from "react-router-dom";
import SearchCandidate from "./searchCandidate";
import UpdateCandidate from "./updateCandidate";
import PreviewForm from "./previewForm";

export default function Index() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  return (
    <>
      {page === "searchcandidate" && <SearchCandidate />}
      {page === "updatecandidate" && <UpdateCandidate />}
      {page === "preview" && <PreviewForm />}
    </>
  );
}

const candidateActions = {
    GET_FULL_CANDIDATE_DETAILS: 'GET_FULL_CANDIDATE_DETAILS',
    SET_FULL_CANDIDATE_DETAILS: 'SET_FULL_CANDIDATE_DETAILS',

    GET_TICKETS_LIST_BY_CANDIDATE: 'GET_TICKETS_LIST_BY_CANDIDATE',
    SET_TICKETS_LIST_BY_CANDIDATE: 'SET_TICKETS_LIST_BY_CANDIDATE',

    GET_UPLOAD_PERSONAL_DETAILS : 'GET_UPLOAD_PERSONAL_DETAILS',
    SET_UPLOAD_PERSONAL_DETAILS : 'SET_UPLOAD_PERSONAL_DETAILS',
    GET_SEARCH_CANDIDATE_DETAILS : 'GET_SEARCH_CANDIDATE_DETAILS',
    SET_SEARCH_CANDIDATE_DETAILS : 'SET_SEARCH_CANDIDATE_DETAILS',
    SET_UPDATE_CANDIDATE_DETAILS : 'SET_UPDATE_CANDIDATE_DETAILS',
    SET_UPDATE_CANDIDATE_DETAILS : 'SET_UPDATE_CANDIDATE_DETAILS',
    CHECK_DUPLICATE_EMAIL_OR_MOBILENO: 'CHECK_DUPLICATE_EMAIL_OR_MOBILENO',
    GET_PROFILE_PERSONAL_DETAILS : 'GET_PROFILE_PERSONAL_DETAILS',
    SET_PROFILE_PERSONAL_DETAILS : 'SET_PROFILE_PERSONAL_DETAILS',
    UPDATE_CANDIDATE_DETAILS : 'UPDATE_CANDIDATE_DETAILS',

}

export default candidateActions;
import { Box, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import customerActions from "../../redux/customer/actions";
import DatewiseTable from "../../common/DatewiseTable";

export default function Index() {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);

  const { dateWiseRegistration } = useSelector(
    (state) => state.customerReducer
  );

  console.log("dateWiseRegistration", dateWiseRegistration);

  useEffect(() => {
    dispatch({ type: customerActions.GET_DATEWISE_REPORT });
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(dateWiseRegistration)) {
      const allCourses = dateWiseRegistration.flatMap((entry) => {
        const confirmed =
          entry.Confirmed && Array.isArray(entry.Confirmed)
            ? entry.Confirmed[0]
            : {};
        return confirmed.Courses ? Object.keys(confirmed.Courses) : [];
      });
      setCourses(Array.from(new Set(allCourses)));
    } else {
      console.error(
        "dateWiseRegistration is not an array",
        dateWiseRegistration
      );
    }
  }, [dateWiseRegistration]);

  const truncateCourseName = (courseName) => {
    if (courseName.length > 20) {
      return (
        <>
          {courseName.slice(0, 20)}
          <br />
          {courseName.slice(20)}
        </>
      );
    }
    return courseName;
  };

  const applicationStatusColumns = [
    {
      name: "",
      colSpan: 2,
      subHeaders: [
        { name: "Sr.No", dataIndex: "srNo" },
        { name: "Date (YYYY/MM/DD)", dataIndex: "reportDate" },
      ],
    },
    {
      name: "Registration",
      colSpan: 2,
      subHeaders: [
        { name: "Daily", dataIndex: "registrationDaily" },
        { name: "Cumulative", dataIndex: "registrationCumulative" },
      ],
    },
    {
      name: "",
      dataIndex: "",
      isPlaceholder: true,
    },
    {
      name: "Confirmation",
      colSpan: 3 + courses.length,
      subHeaders: [
        { name: "Confirm", dataIndex: "confirmationConfirm" },
        { name: "Cumulative", dataIndex: "confirmationCumulative" },
        ...courses.map((course) => ({
          name: truncateCourseName(course),
          dataIndex: course,
        })),
        { name: "Total", dataIndex: "total" },
      ],
    },
  ];

  const applicationStatusData = Array.isArray(dateWiseRegistration)
    ? dateWiseRegistration
        .filter(
          (entry) =>
            entry.date &&
            entry.Confirmed &&
            Array.isArray(entry.Confirmed) &&
            entry.Confirmed[0].Courses
        )
        .map((entry, index) => {
          const registered =
            entry.Registerd && Array.isArray(entry.Registerd)
              ? entry.Registerd[0]
              : {};
          const confirmed =
            entry.Confirmed && Array.isArray(entry.Confirmed)
              ? entry.Confirmed[0]
              : {};

          const courseData = confirmed.Courses ? confirmed.Courses : {};

          const courseValues = courses.reduce((acc, course) => {
            acc[course] = courseData[course] || 0;
            return acc;
          }, {});

          return {
            srNo: index + 1,
            reportDate: entry.date || "N/A",
            registrationDaily: registered.registeredcount || 0,
            registrationCumulative: registered.cumulativecount || 0,
            confirmationConfirm: confirmed.confirmedcount || 0,
            confirmationCumulative: confirmed.cumulativecount || 0,
            total: confirmed.confirmedcount || 0,
            ...courseValues,
          };
        })
    : [];

  console.log("application data:", applicationStatusData);

  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography>Date wise Registration Report</Typography>

          <Box sx={{ marginTop: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "black",
                fontSize: "20px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "whitesmoke",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Date wise Registration Status{" "}
            </Typography>
          </Box>

          <DatewiseTable
            columnData={applicationStatusColumns}
            tableData={applicationStatusData}
          />
        </Card>
      </Box>
    </>
  );
}

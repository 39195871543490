import React from "react";
import MasterDashboard from "./masterDashboard";
import { useSelector } from "react-redux";
import Sidebar from "../layouts/sidebar";
import Header from "../layouts/header";
import { Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";
import HelpDeskLandingPage from "./helpdeskLandingPage";
import TicketList from "../components/ticketList/index";
import RaiseTicket from "../components/raiseTicket";
import BackOfficeDashboard from '../components/BackOfficeDashboard';
import ObjectionTrackerDashboard from "../components/objectionTrackerDashboard/index";
import CustomerDashboard from "../components/CustomerDashboard/index";

import ObjectionTrackerView from "../components/ObjectionTrackerView/index";
import CommunicationManagement from "../components/communicationManagement/index";


export default function Home() {

    const { backofficeTokenDetails } = useSelector(state => state.authReducer);

    const breadcrumbs = [
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Dashboard <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];

    return (
        <div>
            <Sidebar />
            <Header breadcrumbs={breadcrumbs} />
            <Layout>
                <PageHeading
                    title={`Welcome ${backofficeTokenDetails?.name}`}
                // dashboard={(backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator') ||
                //     backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution') ||
                //     backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Help Desk')) ? 'helpdesk' : ''}
                />
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Super Admin') &&
                    <MasterDashboard />
                }
                {(backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator')) &&
                    <HelpDeskLandingPage />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution') &&
                    <TicketList />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Help Desk') &&
                    <RaiseTicket />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Back Office') &&
                    <BackOfficeDashboard />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Objection Coordinator') &&
                    <ObjectionTrackerDashboard  />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Objection Reviewer') &&
                    <ObjectionTrackerView role={"Objection Reviewer"} />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Communication') &&
                    <CommunicationManagement />
                }
                {backofficeTokenDetails?.role?.role?.find(val => val === 'Customer') &&
                    <CustomerDashboard />
                }
                
            </Layout>
        </div>
    )
}
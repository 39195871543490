import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DataTable from "../../common/dataTable";
import { itemsPerPage } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import candidateActions from "../../redux/candidate/actions";
import TablePagination from "../../common/tablePagination";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { EncryptFunction } from "../../utils/cryptoFunction";

export default function SearchCandidate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { candidateDetails } = useSelector((state) => state.candidateReducer);
  const [tableData, setTableData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleSearch = (data) => {
    dispatch({
      type: candidateActions.GET_SEARCH_CANDIDATE_DETAILS,
      payload: data,
    });
  };

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = tableData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(tableData?.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

  const tableKeys = [
    {
      name: "S.No",
      accessor: "s.no",
      sortOption: false,
    },
    {
      name: "Candidate Name",
      accessor: "candidatename",
      sortOption: false,
    },
    {
      name: "Email Id",
      accessor: "emailid",
      sortOption: false,
    },
    {
      name: "Mobile No",
      accessor: "mobileno",
      sortOption: false,
    },
    {
      name: "DOB",
      accessor: "dob",
      sortOption: false,
    },
  ];

  useEffect(() => {
    setTableData(candidateDetails);
  }, [candidateDetails]);

  const handlePreviewForm = (val) => {
    navigate(`/${Cookies.get("mhet_mst_project")}/candidate-management?page=preview&&cid=${EncryptFunction(val?.candidateid)}`)
  };

  const handleEditCandidateDetails = (val) => {
    navigate(`/${Cookies.get("mhet_mst_project")}/candidate-management?page=updatecandidate&&cid=${EncryptFunction(val?.candidateid)}`)
  };

  return (
    <>
      <Typography sx={{ color: "#ff0000", fontSize: "24px" }}>
        Instructions :
      </Typography>
      <Grid
        sx={{
          backgroundColor: "#f6e9e9",
          border: "1px solid #ff0000",
          fontSize: "16px",
          color: "#ff0000",
          lineHeight: "24px",
          borderRadius: "5px",
        }}
        component="p"
        p={1}
      >
        • Please enter candidate's name and/or e-mail id and/or mobile no and/or
        DOB to search candidate. <br />• Please enter atleast one parameter.
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item md={4} sm={12}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label={<span>Candidate Name</span>}
            placeholder="Enter name"
            {...register("candidatename", {
              required:
                watch("emailid") || watch("mobileno") || watch("dob")
                  ? false
                  : "Atleast candidate name or email id or mobile no or DOB is required",
            })}
            error={!!errors.candidatename}
            helperText={errors?.candidatename?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextField
            fullWidth
            type="text"
            label={<span>Email Id</span>}
            placeholder="Enter email id"
            {...register("emailid", {
              required:
                watch("candidatename") || watch("mobileno") || watch("dob")
                  ? false
                  : "Atleast candidate name or email id or mobile no or DOB is required",
            })}
            error={!!errors.emailid}
            helperText={errors.emailid?.message}
            FormHelperTextProps={{
              style: {
                margin: 0,
              },
            }}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextField
            fullWidth
            type="text"
            label={<span>Mobile No</span>}
            placeholder="Enter mobile no"
            {...register("mobileno", {
              required:
                watch("emailid") || watch("candidatename") || watch("dob")
                  ? false
                  : "Atleast candidate name or email id or mobile no or DOB is required",
            })}
            error={!!errors.mobileno}
            helperText={errors?.mobileno?.message}
            FormHelperTextProps={{
              style: {
                margin: 0,
              },
            }}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              fullWidth
              name="dob"
              id="dob"
              {...register("dob", {
                required:
                  watch("emailid") ||
                  watch("candidatename") ||
                  watch("mobileno")
                    ? false
                    : "Atleast candidate name or email id or mobile no or DOB is required",
              })}
              onChange={(date) => {
                const localDate = dayjs(date)
                  .startOf("day")
                  .format("DD-MM-YYYY");
                setValue("dob", localDate, { shouldValidate: true });
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  helperText: errors.dob ? errors.dob.message : "",
                  error: !!errors.dob,
                  style: {
                    width: "100%",
                    borderRadius: "8px",
                  },
                  FormHelperTextProps: {
                    style: {
                      margin: 0,
                    },
                  },
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          md={4}
          sm={12}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <MauvelousPrimaryButton
            title={"Search Candidate"}
            handleClick={handleSubmit(handleSearch)}
          />
        </Grid>
        <Grid item md={4} sm={12}></Grid>
        <Grid item md={12} sm={12}>
          <Typography sx={{ fontSize: "24px" }}>Candidate Details</Typography>
        </Grid>
        <Grid item md={12} sm={12} pb={2}>
          <DataTable
            keys={tableKeys}
            values={currentItems}
            page={"coursemanagement"}
            handlePreviewForm={handlePreviewForm}
            handleEditCandidateDetails={handleEditCandidateDetails}
          />
          <TablePagination
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        </Grid>
      </Grid>
    </>
  );
}

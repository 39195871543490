import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import DashboardTable from "../../common/dashboardTable";
import masterActions from "../../redux/master/actions";
import customerActions from "../../redux/customer/actions";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "../../common/tablePagination";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";

let applicationStatusColumns = [
  {
    name: "Application Status",
    dataIndex: "status",
  },
  {
    name: "Total",
    dataIndex: "candidatecount",
  },
];
let candidatureTypeColumns = [
  {
    name: "Candidature Type",
    dataIndex: "candidate_type",
  },
  {
    name: "Total",
    dataIndex: "candidate_count",
  },
];
let regionWiseColumns = [
  {
    name: "Region ",
    dataIndex: "region_type",
  },
  {
    name: "Total",
    dataIndex: "region_count",
  },
];
let categoryWiseColumns = [
  {
    name: "Category",
    dataIndex: "category_type",
  },
  {
    name: "Total",
    dataIndex: "category_count",
  },
];
let genderWiseColumns = [
  {
    name: "Gender",
    dataIndex: "gender_type",
  },
  {
    name: "Total",
    dataIndex: "gender_count",
  },
];
let PWDWiseColumns = [
  {
    name: "PWD Type",
    dataIndex: "pwd_type",
  },
  {
    name: "Total",
    dataIndex: "pwd_count",
  },
];
let stateWiseColumns = [
  {
    name: "state",
    dataIndex: "state_name",
  },
  {
    name: "Total",
    dataIndex: "state_count",
  },
];
let districtWiseColumns = [
  {
    name: "district",
    dataIndex: "district_name",
  },
  {
    name: "Total",
    dataIndex: "district_count",
  },
];
let religionWiseColumns = [
  {
    name: "religion",
    dataIndex: "religion_type",
  },
  {
    name: "Total",
    dataIndex: "religion_count",
  },
];
let stepWiseColumns = [
  {
    name: "step",
    dataIndex: "status_description",
  },
  {
    name: "Total",
    dataIndex: "candidatecount",
  },
];
let examcenterWiseColumns = [
  {
    name: "Exam center",
    dataIndex: "district",
  },
  {
    name: "Total",
    dataIndex: "candidatecount",
  },
];
const CustomerDashboard = ({ tableData, columnData }) => {
  const dispatch = useDispatch();
  const [courseid, setCourseId] = useState(0);
  const { courseList } = useSelector((state) => state.masterReducer);
  const { reports } = useSelector((state) => state.customerReducer);
  const [courseError, setCourseError] = useState("");
  const [isReportGenerated, setIsReportGenerated] = useState(false);

  console.log(reports, "rpts");

  const [rows, setRows] = React.useState(tableData || []);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedRows = rows.slice(startIndex, endIndex);

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, []);
  const onChangeCourse = (e) => {
    setCourseId(e.target.value);
  };

  // useEffect(() => {
  //   courseid &&
  //     dispatch({
  //       type: customerActions.GET_REPORTS,
  //       payload: { courseid: courseid },
  //     });
  // }, [courseid]);

  const handleGenerate = () => {
    if (courseid === 0) {
      setCourseError("Course is required");
      return;
    }

    dispatch({
      type: customerActions.GET_REPORTS,
      payload: { courseid: courseid },
    });
    setIsReportGenerated(true);
  };

  console.log(reports?.religionwisereport, "religionwisereport");
  console.log(reports?.statewisereport, "statewisereport");

  return (
    <>
      <Card sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Dashboard
        </Typography>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={4} sx={{ mr: 2 }}>
            <FormControl error={!!courseError} sx={{ width: "100%" }}>
              <Select
                size="small"
                onChange={onChangeCourse}
                value={courseid}
                defaultValue={0}
                sx={{ height: "56px" }}
              >
                <MenuItem value={0} selected>
                  Select Course <span style={{ color: "red" }}>*</span>
                </MenuItem>
                {courseList?.all
                  ?.filter((course) => course.isactive === "1")
                  .map((course, i) => (
                    <MenuItem key={i} value={course.courseid}>
                      {course.coursename}
                    </MenuItem>
                  ))}
              </Select>
              {courseError && (
                <Typography variant="caption" color="error">
                  {courseError}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Box sx={{ marginTop: 3 }}>
            <BluePrimaryButton
              title={"Generate"}
              handleSubmit={handleGenerate}
            />
          </Box>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Application Status Wise Report
              </Typography>
              <DashboardTable
                columnData={applicationStatusColumns}
                tableData={reports?.applicationstatuswisereports}
              />
            </Card>
          </Grid>

          <Grid item md={6} sm={12}>
            <Card sx={{ p: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Candidature Type Wise Report
              </Typography>
              <DashboardTable
                columnData={candidatureTypeColumns}
                tableData={reports?.candidaturetypewisereport}
              />
            </Card>

            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Category Wise Report
              </Typography>
              <DashboardTable
                columnData={categoryWiseColumns}
                tableData={reports?.categorywisereport}
              />
            </Card>

            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                State Wise Report
              </Typography>
              <DashboardTable
                columnData={stateWiseColumns}
                tableData={reports?.statewisereport}
              />
            </Card>

            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Religion Wise Report
              </Typography>
              <DashboardTable
                columnData={religionWiseColumns}
                tableData={reports?.religionwisereport}
              />
            </Card>

            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Exam Center Wise Report
              </Typography>
              <DashboardTable
                columnData={examcenterWiseColumns}
                tableData={reports?.examcenterwisereport}
              />
            </Card>
          </Grid>

          <Grid item md={6} sm={12}>
            <Card sx={{ p: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Region Wise Report
              </Typography>
              <DashboardTable
                columnData={regionWiseColumns}
                tableData={reports?.regionwisereport}
              />
            </Card>

            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Gender Wise Report
              </Typography>
              <DashboardTable
                columnData={genderWiseColumns}
                tableData={reports?.genderwisereport}
              />
            </Card>

            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                PWD Wise Report
              </Typography>
              <DashboardTable
                columnData={PWDWiseColumns}
                tableData={reports?.pwdwisereport}
              />
            </Card>
            <Card sx={{ p: 2, mt: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                Step Wise Report
              </Typography>
              <DashboardTable
                columnData={stepWiseColumns}
                tableData={reports?.stepwisereport}
              />
            </Card>
            <Card sx={{ p: 2, mt: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 2 }}>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="rows-per-page-label">
                    Rows per page
                  </InputLabel>
                  <Select
                    labelId="rows-per-page-label"
                    id="rows-per-page"
                    value={rowsPerPage}
                    label="Rows per page"
                    size="small"
                    onChange={handleRowsPerPageChange}
                    sx={{ minWidth: 100 }}
                  >
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography sx={{ textAlign: "center" }}>
                District Wise Report
              </Typography>
              <DashboardTable
                columnData={districtWiseColumns}
                tableData={reports?.districtwisereport}
              />
              <Box sx={{ mt: 3 }}>
                <TablePagination
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CustomerDashboard;

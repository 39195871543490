import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonTable from "../../common/CommonTable";
import GreenButton from "../../common/button/greenButton";
import VerticalTable from "../../common/verticalTable";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import customerActions from "../../redux/customer/actions";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";

export default function Index() {
  const dispatch = useDispatch();
  const [courseid, setCourseId] = useState(0);
  const [applicantionid, setapplicantionId] = useState("");
  const [emailid, setEmailId] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const { courseList } = useSelector((state) => state.masterReducer);
  const { applicationStatus } = useSelector((state) => state.customerReducer);
  const [courseError, setCourseError] = useState("");
  const [commonError, setCommonError] = useState("");
  const [stepsData, setStepsData] = useState("");

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, []);

  const onChangeCourse = (e) => {
    setCourseId(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailId(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobileNo(e.target.value);
  };

  const handleApplicationChange = (e) => {
    setapplicantionId(e.target.value);
  };

  const handleGenerate = () => {
    let hasError = false;

    if (courseid === 0) {
      setCourseError("Course is required");
      hasError = true;
    } else {
      setCourseError("");
    }

    if (!emailid && !mobileno && !applicantionid) {
      setCommonError(
        "Atleast Email ID or Mobile No or Application No or DOB or Candidate Name is required"
      );
      hasError = true;
    } else {
      setCommonError("");
    }

    if (!hasError) {
      dispatch({
        type: customerActions.GET_APPLICATION_STATUS,
        payload: {
          courseid: courseid,
          emailid: emailid,
          mobileno: mobileno,
          applicantionid: applicantionid,
        },
      });
    }
  };

  let applicationColumns = [
    {
      name: "Application No",
      dataIndex: "applicationNo",
    },
    {
      name: "Candidate's Name",
      dataIndex: "candidateName",
    },
  ];

  const dataTableData =
    applicationStatus?.applicationstatusreport?.map((item) => ({
      applicationNo: item.applicantionid || "N/A",
      candidateName: item.candidatename,
    })) || [];

    useEffect(() => {
      if (applicationStatus?.applicationstatusreport) {
        const status = applicationStatus.applicationstatusreport[0]?.status;
        const isApplicationPrinted = applicationStatus.applicationstatusreport[0]?.isApplicationPrinted;
    
        const totalSteps = 9;
        const stepsData = Array.from({ length: totalSteps }, (_, index) => {
          const stepId = index + 1;
          let stepDetails = "";
    
          switch (stepId) {
            case 1:
            case 2:
              stepDetails = "Registration/Personal Details";
              break;
            case 3:
              stepDetails = "Domicile and Category Details";
              break;
            case 4:
              stepDetails = "Qualification Details";
              break;
            case 5:
              stepDetails = "Set Examination Center Preferences";
              break;
            case 6:
              stepDetails = "Upload Required Document for Proof of Identity";
              break;
            case 7:
              stepDetails = "Preview and Validate Information";
              break;
            case 8:
              stepDetails = "Fee Paid And Confirmed";
              break;
            case 9: 
              stepDetails = "Application Printed";
              break;
            default:
              stepDetails = "Not Registered";
              break;
          }
    
          let stepStatus = "";
    
          if (isApplicationPrinted && stepId === 9) {
            stepStatus = <GreenButton title="complete" />;
          } else if (status >= stepId) {
            stepStatus = <GreenButton title="complete" />;
          }
    
          return {
            stepId: `Step ${stepId}`,
            stepDetails,
            status: stepStatus,
          };
        });
    
        setStepsData(stepsData);
      }
    }, [applicationStatus]);
    
  let applicationStatusColumns = [
    {
      name: "Step ID",
      dataIndex: "stepId",
    },
    {
      name: "Step Details",
      dataIndex: "stepDetails",
    },
    {
      name: "Status",
      dataIndex: "status",
    },
  ];

  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography>Check Application Status</Typography>

          <Grid item sm={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ gap: 2, flexWrap: "wrap", mt: 2 }}
            >
              <FormControl error={!!courseError} sx={{ width: "220px" }}>
                <Select
                  size="small"
                  onChange={onChangeCourse}
                  value={courseid}
                  defaultValue={0}
                  sx={{ height: "56px" }}
                >
                  <MenuItem value={0} selected>
                    Select Course <span style={{ color: "red" }}>*</span>
                  </MenuItem>
                  {courseList?.all
                    ?.filter((course) => course.isactive === "1")
                    .map((course, i) => (
                      <MenuItem key={i} value={course.courseid}>
                        {course.coursename}
                      </MenuItem>
                    ))}
                </Select>
                {courseError && (
                  <Typography variant="caption" color="error">
                    {courseError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "220px" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-email">
                  Search by Email ID
                </InputLabel>
                <OutlinedInput
                  id="search-email"
                  value={emailid}
                  onChange={handleEmailChange}
                  label="Search by Email ID"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "220px" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-mobile">
                  Search by Mobile No
                </InputLabel>
                <OutlinedInput
                  id="search-mobile"
                  value={mobileno}
                  onChange={handleMobileChange}
                  label="Search by Mobile No"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "220px" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-application">
                  Search by Application No
                </InputLabel>
                <OutlinedInput
                  id="search-application"
                  value={applicantionid}
                  onChange={handleApplicationChange}
                  label="Search by Application No"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <Box sx={{ marginTop: 1 }}>
                <BluePrimaryButton
                  title={"Generate"}
                  handleSubmit={handleGenerate}
                />
              </Box>
            </Box>
          </Grid>

          <Box sx={{ mb: 2 }}>
            <VerticalTable
              columnData={applicationColumns}
              tableData={dataTableData || []}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "black",
                fontSize: "20px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "whitesmoke",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Application Form Status{" "}
            </Typography>
          </Box>

          <CommonTable
            columnData={applicationStatusColumns}
            tableData={stepsData || []}
          />
        </Card>
      </Box>
    </>
  );
}

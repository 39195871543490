import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,

} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import candidateActions from "../../redux/candidate/actions";
import { DecryptFunction } from "../../utils/cryptoFunction";

export default function PreviewForm({ targetRef }) {

  const dispatch = useDispatch();
  const { personalDetails,fullCandidateDetails,uploadDetails } = useSelector((state) => state.candidateReducer);
  const [searchParams] = useSearchParams();
  const candidateId = DecryptFunction(searchParams.get("cid"));
  

  useEffect(() => {
    if (candidateId) {
      dispatch({ type: candidateActions.GET_PROFILE_PERSONAL_DETAILS, payload: { id:parseInt(candidateId) } });
      dispatch({ type: candidateActions.GET_UPLOAD_PERSONAL_DETAILS, payload: { id:parseInt(candidateId) }  });
      dispatch({
        type: candidateActions.GET_FULL_CANDIDATE_DETAILS,
        payload: { id: parseInt(candidateId)},
      });
    }
  }, [candidateId]);


  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
      ref={targetRef}
    >
      <Grid container mt={1}>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Personal Details</Typography>
            </Box>

          </Box>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Candidate’s Full name
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {personalDetails[0]?.candidatename}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Father’s Full Name
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.fathername}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Mother’s Full Name
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.mothername}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Marital Status
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(fullCandidateDetails?.maritalstatusid) === 1
                  ? "Married"
                  : parseInt(fullCandidateDetails?.maritalstatusid) === 2
                  ? "Unmarried"
                  : "----"}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Spouse’s Full Name
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {
                  (fullCandidateDetails?.spousename ? fullCandidateDetails?.spousename : "-")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.gender}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Date of Birth
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {personalDetails[0]?.dob &&
                  dayjs(personalDetails[0]?.dob).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Religion
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.religion}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Region
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.region}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Mother Tongue
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.mothertongue}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Annual Income
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.familyincome}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Nationality
              </Typography>
            </Grid>
            <Grid item md={9} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.nationality}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Permanent Address</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.paddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.paddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {
                  (fullCandidateDetails?.paddressline3 ? fullCandidateDetails?.paddressline3 : "-")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.pstate}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.pdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.ptaluka}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.pvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Pin code
              </Typography>
            </Grid>
            <Grid item md={3} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.ppincode}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Telephone No
              </Typography>
            </Grid>
            <Grid item md={3} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.phoneno}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Address for Correspondence
              </Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.caddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.caddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {
                  (fullCandidateDetails?.caddressline3 ? fullCandidateDetails?.caddressline3 : "-")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.cstate}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.cdistrict === null
                  ? "Not Applicable"
                  : fullCandidateDetails?.cdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.ctaluka === null
                  ? "Not Applicable"
                  : fullCandidateDetails?.ctaluka}
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.cvillage === null
                  ? "Not Applicable"
                  : fullCandidateDetails?.cvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Pin code
              </Typography>
            </Grid>
            <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {fullCandidateDetails?.cpincode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
              <Box
                p={1}
                sx={{
                  height: "48px",
                  backgroundColor: "#EDEDED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Other Details
                  </Typography>
                </Box>
              </Box>
              <Grid container md={12}>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Are You Domiciled In The State Of Maharastra
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(fullCandidateDetails?.ismhcandidate) == 1
                        ? "Yes"
                        : parseInt(fullCandidateDetails?.ismhcandidate) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Do You Belong To Minority Category
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(fullCandidateDetails?.isminority) == 1
                        ? "Yes"
                        : parseInt(fullCandidateDetails?.isminority) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>

                  {fullCandidateDetails?.linguisticminority &&
                  <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Linguistic Minority
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {fullCandidateDetails?.linguisticminority}
                    </Typography>
                  </Grid>
                  </>}
                  
                  {fullCandidateDetails?.religiousminority &&
                  <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Religious Minority
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {fullCandidateDetails?.religiousminority}
                    </Typography>
                  </Grid>
                  </>}
                  
                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Are You Person With Disability
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(fullCandidateDetails?.isdisability) == 1
                        ? "Yes"
                        : parseInt(fullCandidateDetails?.isdisability) == 0
                        ? "No"
                        : "---"}
                    </Typography>
                  </Grid>
                  
                  {fullCandidateDetails?.disabilitytype && 
                  <>
                    <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Type Of Disability
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {fullCandidateDetails?.disabilitytype}
                    </Typography>
                  </Grid>
                  </>}
                  
                  
                  {fullCandidateDetails?.disabilitysubtype && 
                  <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Sub Type Of Disability
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {fullCandidateDetails?.disabilitysubtype}
                    </Typography>
                  </Grid>
                  </>}
                  
                  {fullCandidateDetails?.isscriberequired && 
                  <>
<Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Require Scribe
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(fullCandidateDetails?.isscriberequired) == 1
                        ? "Yes"
                        : parseInt(fullCandidateDetails?.isscriberequired) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                  </>}
                  

                  {fullCandidateDetails?.isextratimerequired && 
                  <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    p={1}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                    >
                      Require Extra Time
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    p={1}
                    xs={3}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(fullCandidateDetails?.isextratimerequired) == 1
                        ? "Yes"
                        : parseInt(fullCandidateDetails?.isextratimerequired) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                  </>}
                  
                  <Grid item xs={12}>
                  <Box
                p={1}
                sx={{
                  height: "48px",
                  backgroundColor: "#EDEDED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Education Details
                  </Typography>
                </Box>
              </Box>
              </Grid>
              <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    SSC/Equivalent Passing Year
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.sscpassingyear}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    SSC/Equivalent Total Percentage
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.ssctotalpercentage}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    SSC/Equivalent Board
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.sscboard}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    SSC School State
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.sscschoolstate}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    Are You Appearing/Appeared For HSC/Diploma exam in 2025
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(fullCandidateDetails?.appearingfor) === 0 ? 'No' : 'Yes'}
                  </Typography>
                </Grid>
                
                {fullCandidateDetails?.hscpassingyear && 
                <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    HSC/Diploma Passing Year
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.hscpassingyear}
                  </Typography>
                </Grid>
                </>}
                
                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    HSC/Diploma Board
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.hscboard}
                  </Typography>
                </Grid>

                {fullCandidateDetails?.marktype && 
                <>
                  <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    HSC/Diploma Marks Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(fullCandidateDetails?.marktype) === 1
                      ? "CGPA"
                      : parseInt(fullCandidateDetails?.marktype) === 2
                      ? "Percentage"
                      : "-----"}
                  </Typography>
                </Grid>
                </>}
                
                {fullCandidateDetails?.hscmarksobtained &&
                <>
                  <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    HSC/Diploma Marks Obtained
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.hscmarksobtained}
                  </Typography>
                </Grid>
                </>}
                
                {fullCandidateDetails?.hscmarksoutof &&
                <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    HSC/Diploma Marks Out Of
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  p={1}
                  xs={3}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.hscmarksoutof}
                  </Typography>
                </Grid>
                </>}
                
                {fullCandidateDetails?.hscpercentage &&
                <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
                  >
                    HSC/Diploma Percentage/CGPA
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  p={1}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {fullCandidateDetails?.hscpercentage}
                  </Typography>
                </Grid>
                </>}
                
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Photograph and Signature
              </Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid item md={6} xs={6} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Photograph
              </Typography>
            </Grid>
            <Grid item md={6} p={1} xs={6} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails? uploadDetails?.find((item,index)=>item?.documenttype == 1)?.fileurl : ""}
                alt="photo"
              />
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails? uploadDetails?.find((item,index)=>item?.documenttype == 2)?.fileurl : ""}
                alt="signatute"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid
            container
            p={1}
            sx={{
              height: "48px",
              width: "100%",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Document Uploaded</Typography>
            </Box>
          </Grid>
          <Grid container md={12}>
            <Grid item md={2} xs={2} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "#3F41D1",
                  fontWeight: 500,
                }}
              >
                S.No
              </Typography>
            </Grid>
            <Grid
              item
              md={10}
              p={1}
              xs={10}
              sx={{ border: "1px solid #F7F7F7" }}
            >
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={2} xs={2} p={1} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid item md={4} p={1} xs={4} sx={{ border: "1px solid #F7F7F7" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {uploadDetails? uploadDetails?.find((item)=>item?.documenttype == 1)?.documentname : ""}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{
                border: "1px solid #F7F7F7",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="a"
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "#3F41D1",
                  fontWeight: 500,
                  textAlign: "center",
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                target="_blank"
                href={uploadDetails? uploadDetails?.find((item)=>item?.documenttype == 3)?.fileurl : ""}
              >
                View
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}

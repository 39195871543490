import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { cssProperties } from "../utils/commonCssProperties";
import { Box } from "@mui/material";

export default function ConsolidateTable({ tableData, columnData, totalData }) {
  const [rows, setRows] = React.useState(tableData);

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  // Group rows by category
  const groupedRows = rows.reduce((acc, row) => {
    if (!acc[row.coursecategory]) {
      acc[row.coursecategory] = [];
    }
    acc[row.coursecategory].push(row);
    return acc;
  }, {});

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          background: "none",
          border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
          boxShadow: "none",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnData?.map((obj, i) => (
                <TableCell
                  sx={{
                    color: cssProperties.bordercolor.primary2blue,
                    textAlign: "center",
                    borderRight: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                  }}
                  key={i}
                >
                  {obj.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedRows).map((category, categoryIndex) =>
              groupedRows[category].map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columnData.map((param, cellIndex) => {
                    if (cellIndex === 1 && rowIndex === 0) {
                      return (
                        <TableCell
                          key={cellIndex}
                          rowSpan={groupedRows[category].length}
                          sx={{
                            textAlign: "center",
                            fontWeight: 500,
                            verticalAlign: "middle",
                            borderRight: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                          }}
                        >
                          {category}
                        </TableCell>
                      );
                    } else if (cellIndex === 1 && rowIndex > 0) {
                      return null;
                    }
                    return (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          textAlign: "center",
                          borderRight: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                        }}
                      >
                        {row[param.dataIndex]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
            <TableRow>
              {columnData.map((param, cellIndex) => (
                <TableCell
                  component="td"
                  key={cellIndex}
                  sx={{
                    textAlign: cellIndex === 0 ? "center" : "center",
                    borderRight:
                      cellIndex === 0 || cellIndex === 1
                        ? "none"
                        : `1px solid ${cssProperties.bordercolor.primary2blue}`,
                    fontWeight: "bold",
                  }}
                >
                  {cellIndex === 0 ? "Total" : totalData[param.dataIndex] || ""}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

import candidateActions from "./actions";

const initialState = {
  fullCandidateDetails: null,
  candidateTicketList: null,
  uploadDetails: [],
  candidateDetails: [],
  candidateUpdateDetails: [],
  personalDetails:[]
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case candidateActions.SET_FULL_CANDIDATE_DETAILS:
      return {
        ...state,
        fullCandidateDetails: action.payload,
      };
    case candidateActions.SET_SEARCH_CANDIDATE_DETAILS:
      return {
        ...state,
        candidateDetails: action.payload,
      };
    case candidateActions.SET_UPDATE_CANDIDATE_DETAILS:
      return {
        ...state,
        candidateUpdateDetails: action.payload,
      };
    case candidateActions.SET_TICKETS_LIST_BY_CANDIDATE:
      return {
        ...state,
        candidateTicketList: action.payload,
      };
      case candidateActions.SET_PROFILE_PERSONAL_DETAILS:
        return {
          ...state,
          personalDetails: action.payload,
        };  
    case candidateActions.SET_UPLOAD_PERSONAL_DETAILS:
      return {
        ...state,
        uploadDetails: action.payload,
      };
    default:
      return state;
  }
};

export default candidateReducer;

export const AlphabetsValidation = (e) => {
    if (e.target.value.length < 10) {
        let value = e.target.value.replace(/[^a-z\s]/gi, '');
        if (value[0] === ' ') {
            value = value.slice(1);
        }
        return e.target.value = value;
    }
}

export const InputTrimFunction = (e) => {
    if (e.target.value?.length > 0) {
        return e.target.value = e.target.value?.trim();
    }
};

export const InputTrimAndLowercaseFunction = (e) => {
    if (e.target.value?.length > 0) {
        return e.target.value = e.target.value?.trim()?.toLowerCase();
    }
};

export const handleEmailValidation = email => {
    // eslint-disable-next-line no-useless-escape
    const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );
    let error;
    if (!isValid) {
        error = 'Enter valid Email id';
    }
    return error;
};

export const passwordValidation = (password) => {
    const isValid = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(
        password
    );
    let error;
    if (!isValid) {
        error = 'Password must contain at least one uppercase letter, one number, and one special character';
    }
    return error;
};

export const MobileNumberFirstNumberValidation = (number) => {
    if (number?.length > 0) {
        let value = number;
        if (parseInt(value?.slice(0, 1)) < 6) {
            return 'Enter valid mobile number';
        }
    }
};

export const MobileNumberValidation = (e) => {
    if (e.target.value.length < 10) {
        const cleanedValue = e.target.value.replace(/\D/g, '');
        const formattedValue = cleanedValue.slice(0, 10);

        e.target.value = formattedValue;
    }
};

export const pincodeValidation = (input) => {
    const isValid = /^[0-9]{6}$/.test(input);
    let error = null;

    if (!isValid) {
        error = 'Invalid input. Pincode must be 6 digits long.';
    }

    return error;
};

export const NumbersValidation = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    e.target.value = value;
    return value;
};

export const FamilyIncomeNumberValidation = (e) => {
    const newValue = e.target.value?.replace(/[^0-9,>=<=-]/g, '');
    e.target.value = newValue;
    return newValue;
};

export const calculateDaysDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay));
    return diffDays || "0";
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

export const hashEmail = (email) => {
    const atIndex = email.indexOf('@');
    const username = email.slice(0, atIndex);
    const hashedEmail = username.substring(0, 3) + '******' + email.substring(atIndex);
    return hashedEmail;
};

// Function to hash mobile number
export const hashMobile = (mobile) => {
    const hashedMobile = mobile.substring(0, 4) + '******';
    return hashedMobile;
};

export const DetailsLimitation = ({text, sx, handleViewMore}) => {
    let sentance = text?.slice(0, 100);
    return(
        <>{sentance} <span style={sx} onClick={handleViewMore}>View</span></>
    )
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
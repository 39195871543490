import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterFormsActions from "../../redux/masterForms/actions";
import CreateMasterModal from "./modals/createMasterModal";
import { useForm } from "react-hook-form";
import EditMasterModal from "./modals/editMasterModal";
import commonActions from "../../redux/common/actions";
import { itemsPerPage } from "../../utils/constants";
import DataTable from "../../common/dataTable";
import TablePagination from "../../common/tablePagination";
import { Box } from "@mui/material";
import Searchbar from "../../common/searchbar";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { PiPlusBold } from "react-icons/pi";

const tableKeys = [
    {
        name: "SI.NO",
        accessor: 'id',
        // accessor: 'mothertongueid',
        sortOption: false
    },
    {
        name: "Mother tongue",
        accessor: 'mothertongue',
        sortOption: false
    },
];

export default function MothertongueMaster() {

    const dispatch = useDispatch();

    const { mothertongueList } = useSelector(state => state.masterFormsReducer);

    const { backofficeTokenDetails } = useSelector(state => state.authReducer);

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const { register, handleSubmit, formState: { errors }, reset,getValues,trigger } = useForm({mode:'onChange',reValidateMode: 'onChange',defaultValues: {}});

    useEffect(() => {
        dispatch({ type: masterFormsActions.GET_MOTHERTONGUE });
        //eslint-disable-next-line
    }, []);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = mothertongueList?.filtered?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(mothertongueList?.filtered?.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        const newOffset = (value - 1) * itemsPerPage;
        setItemOffset(newOffset);
        setCurrentPage(value);
    };

    const handleCreate = (data) => {
        data['mothertongue'] = data.name;
        data["status"] = "1";
    data["userid"] = Number(backofficeTokenDetails?.id);
        dispatch({ type: masterFormsActions.CREATE_MOTHERTONGUE, payload: { data: data, resetForm: resetForm } });
    };

    const resetForm = () => {
        reset();
    };

    const handleSearch = (e) => {
        let filtered = [];
        setCurrentPage(1);
        setItemOffset(0);
        if (e.target.value) {
            //eslint-disable-next-line
            filtered = mothertongueList?.all?.filter(val => {
                if (val?.mothertongue?.toLowerCase().includes(e.target.value?.toLowerCase())) {
                    return val;
                }
            });
        } else {
            filtered = [...mothertongueList?.all];
        }
        dispatch({ type: masterFormsActions.SET_MOTHERTONGUE, payload: { ...mothertongueList, filtered: filtered } })
    };

    const handleMasteredit = (page, value) => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: true, type: page, method: 'U', data: null } });
        reset({ name: value?.mothertongue, mothertongueid: value?.mothertongueid, userid: value?.userid });
    };

    const handleUpdate = (data) => {
        data['mothertongue'] = data.name;
        data["status"] = "1";
    data["userid"] = Number(backofficeTokenDetails?.id);
        dispatch({ type: masterFormsActions.UPDATE_MOTHERTONGUE, payload: { id: data?.mothertongueid, data: data, resetForm: resetForm } });
    };

    const handleshowModal = () => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: true, type: 'mothertongue', method: 'C', data: null } });
    };

    return (
        <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '24px', marginBottom: '14px' }}>
        {/* <Box></Box> */}
        <Searchbar height={48} handleSearch={handleSearch} />
                <MauvelousPrimaryButton title={'Add'} icon={<PiPlusBold />} handleClick={handleshowModal} />
            </Box>
            <Box sx={{ height: 'calc(100vh - 280px)' }}>
            <DataTable
                keys={tableKeys}
                values={currentItems}
                page={'mothertongue'}
                handleMasteredit={handleMasteredit}
            />
            </Box>
            <Box sx={{ position: 'fixed', bottom: 0, right: '24px',padding:'15px 0', width: '100%', py: 1 }}>
                    <TablePagination

                        pageCount={pageCount}
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                    />
                </Box>
            <CreateMasterModal
                register={register}
                handleSubmit={handleSubmit} getValues={getValues} trigger={trigger}
                errors={errors}
                handleCreate={handleCreate}
                resetForm={resetForm}
            />
            <EditMasterModal
                register={register}
                handleSubmit={handleSubmit} getValues={getValues} trigger={trigger}
                errors={errors}
                handleUpdate={handleUpdate}
                resetForm={resetForm}
            />
        </>
    )
}
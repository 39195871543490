import React from "react";
import Index from "../components/candidateManagement";
import { Link as MuiLink, Typography } from "@mui/material";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Cookies from "js-cookie";
import Layout from "../layouts/layout";
import Sidebar from "../layouts/sidebar";
import Header from "../layouts/header";
import PageHeading from "../layouts/pageHeading";

export default function CandidateManagement() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const navigate = useNavigate();
  const breadcrumbs = [
    <MuiLink
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_mst_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </MuiLink>,
    <Typography
      key="3"
      color="text.primary"
      onClick={() => {
        navigate(`/${Cookies.get("mhet_mst_project")}/candidate-management?page=searchcandidate`)
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: page === 'searchcandidate' ? 500 : 'none',
        lineHeight: "20px",
        letterSpacing: "0.1px",
        ':hover': {
          cursor: page === 'searchcandidate' ? 'none':'pointer',
          textDecoration: page === 'searchcandidate' ? 'none':'underline',
      }}}
    >
      Candidate Management {page === 'searchcandidate' && <MdKeyboardArrowDown size={16} />} 
    </Typography>,
  ];

  {page === 'updatecandidate' &&
    breadcrumbs.push(<Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Update Candidate <MdKeyboardArrowDown size={16} />
    </Typography>)
  }

  {page === 'preview' &&
    breadcrumbs.push(<Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Preview <MdKeyboardArrowDown size={16} />
    </Typography>)
  }

  return (
    <>
      <Layout>
        <Sidebar />
        <Header breadcrumbs={breadcrumbs} />
        <PageHeading
          title={
            page === "searchcandidate" ? "Search Candidate" : page === "preview" ? 'Preview' : "Update Candidate"
          }
        />
        <Index />
      </Layout>
    </>
  );
}

const customerActions = {
    GET_REPORTS: 'GET_REPORTS',
    SET_REPORTS: 'SET_REPORTS',

    GET_PAYMENT_HISTORY: 'GET_PAYMENT_HISTORY',
    SET_PAYMENT_HISTORY: 'SET_PAYMENT_HISTORY',

    GET_APPLICATION_STATUS: 'GET_APPLICATION_STATUS',
    SET_APPLICATION_STATUS: 'SET_APPLICATION_STATUS',

    GET_SEARCH_CANDIDATE: 'GET_SEARCH_CANDIDATE',
    SET_SEARCH_CANDIDATE: 'SET_SEARCH_CANDIDATE',

    GET_GENERATE_REPORT: 'GET_GENERATE_REPORT',
    SET_GENERATE_REPORT: 'SET_GENERATE_REPORT',

    GET_DATEWISE_REPORT: 'GET_DATEWISE_REPORT',
    SET_DATEWISE_REPORT: 'SET_DATEWISE_REPORT',

    GET_CONSOLIDATE_REPORT: 'GET_CONSOLIDATE_REPORT',
    SET_CONSOLIDATE_REPORT: 'SET_CONSOLIDATE_REPORT',

    GET_GENDERCATEGEORY_REPORT: 'GET_GENDERCATEGEORY_REPORT',
    SET_GENDERCATEGEORY_REPORT: 'SET_GENDERCATEGEORY_REPORT',


};

export default customerActions;
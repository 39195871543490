import {
  Box,
  Button,
  Card,
  
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import customerActions from "../../redux/customer/actions";
import ConsolidateTable from "../../common/consolidateTable";

export default function Index() {
  const dispatch = useDispatch();
  const { consolidateRept } = useSelector((state) => state.customerReducer);
  const currentYear = new Date().getFullYear();

  console.log("consolidateRept", consolidateRept);

  useEffect(() => {
    dispatch({ type: customerActions.GET_CONSOLIDATE_REPORT });
  }, []);

  let applicationStatusColumns = [
    {
      name: "Sr.No",
      dataIndex: "srNo",
    },
    {
      name: "Department Name",
      dataIndex: "coursecategory",
    },
    {
      name: "CET Name",
      dataIndex: "coursename",
    },
    {
      name: "Registered Candidates",
      dataIndex: "registered",
    },
    {
      name: "Registration In Progress",
      dataIndex: "registrationinprogress",
    },
    {
      name: "Fees Paid and Confirmed",
      dataIndex: "feepaid",
    },
    {
      name: "Gender(Others)",
      dataIndex: "gender",
    },
    {
      name: "Orphans",
      dataIndex: "orphan",
    },
  ];

  const applicationStatusData = Array.isArray(consolidateRept)
    ? consolidateRept
        .slice(0, -1) 
        .map((item, index) => ({
          srNo: index + 1,
          coursecategory: item.coursecategory,
          coursename: item.coursename,
          registered: item.registered,
          registrationinprogress: item.registrationinprogress,
          feepaid: item.feepaid,
          gender: item.gender,
          orphan: item.orphan,
        }))
    : [];

  const totalData = {
    registered: applicationStatusData.reduce(
      (sum, item) => sum + parseInt(item.registered || 0, 10),
      0
    ),
    registrationinprogress: applicationStatusData.reduce(
      (sum, item) => sum + parseInt(item.registrationinprogress || 0, 10),
      0
    ),
    feepaid: applicationStatusData.reduce(
      (sum, item) => sum + parseInt(item.feepaid || 0, 10),
      0
    ),
    gender: applicationStatusData.reduce(
      (sum, item) => sum + parseInt(item.gender || 0, 10),
      0
    ),
    orphan: applicationStatusData.reduce(
      (sum, item) => sum + parseInt(item.orphan || 0, 10),
      0
    ),
  };

  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography>Consolidate Report</Typography>

          <Box sx={{ marginTop: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "black",
                fontSize: "20px",
                lineHeight: "24px",
                mb: 1,
                backgroundColor: "whitesmoke",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              CET {currentYear} Consolidate Report{" "}
            </Typography>
          </Box>

          <ConsolidateTable
            columnData={applicationStatusColumns}
            tableData={applicationStatusData}
            totalData={totalData}
          />
        </Card>
      </Box>
    </>
  );
}

import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonTable from "../../common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import customerActions from "../../redux/customer/actions";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import masterActions from "../../redux/master/actions";
import * as XLSX from "xlsx";

export default function Index() {
  const dispatch = useDispatch();
  const [courseid, setCourseId] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const { courseList } = useSelector((state) => state.masterReducer);
  const { generateReports } = useSelector((state) => state.customerReducer);
  const [courseError, setCourseError] = useState("");
  const [isReportGenerated, setIsReportGenerated] = useState(false); 


  console.log(
    generateReports?.candidatewisedatareport,
    "candidatewisedatareport"
  );
  console.log(
    generateReports?.reginprogressdatawisereport,
    "reginprogressdatawisereport"
  );

  console.log(
    generateReports?.districtdatawisereport,
    "districtdatawisereport"
  );

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, []);

  const onChangeCourse = (e) => {
    setCourseId(e.target.value);
    setCourseError("");
  };

  // useEffect(() => {
  //   if (courseid) {
  //     dispatch({
  //       type: customerActions.GET_GENERATE_REPORT,
  //       payload: { courseid: courseid },
  //     });
  //   }
  // }, [courseid]);

  const handleGenerate = () => {
    if (courseid === 0) {
      setCourseError("Course is required");
      return;
    }

    dispatch({
      type: customerActions.GET_GENERATE_REPORT,
      payload: { courseid: courseid },
    });
    setIsReportGenerated(true);

  };

  const handleDownloadExcel = async (reportName) => {
    let data = [];

    switch (reportName) {
      case "Dump-Candidate Data":
        data =
          generateReports?.candidatewisedatareport?.map(
            ({ applicantionid, candidatename, emailid, mobileno }) => ({
              ApplicationNo: applicantionid,
              CandidateName: candidatename,
              EmailID: emailid,
              MobileNo: mobileno,
            })
          ) || [];
        break;

      case "Registration In-Progress Candidates":
        data =
          generateReports?.reginprogressdatawisereport?.map(
            ({
              coursename,
              applicantionid,
              candidatename,
              emailid,
              mobileno,
              status,
            }) => ({
              CourseName: coursename,
              ApplicationNo: applicantionid,
              CandidateName: candidatename,
              EmailID: emailid,
              MobileNo: mobileno,
              "Current Status": status,
            })
          ) || [];
        break;
      case "District-wise Preference Count for Confirmed Candidates":
        data =
          generateReports?.districtdatawisereport?.map(
            ({
              coursename,
              statename,
              districtname,
              pref1district,
              pref2district,
              pref3district,
              pref4district,
            }) => ({
              CourseName: coursename,
              StateName: statename,
              DistrictName: districtname,
              Preference1: pref1district,
              Preference2: pref2district,
              Preference3: pref3district,
              Preference4: pref4district,
            })
          ) || [];
      default:
        break;
    }

    if (data.length === 0) return;

    const worksheet = XLSX.utils.json_to_sheet(data);

    const wscols = [
      { wpx: 130 },
      { wpx: 100 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
    ];

    worksheet["!cols"] = wscols;

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        font: { bold: true },
        alignment: { horizontal: "center", vertical: "center" },
      };
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    XLSX.writeFile(workbook, `${reportName}.xlsx`);
  };

  let applicationStatusColumns = [
    {
      name: "Sr.No",
      dataIndex: "srNo",
    },
    {
      name: "Report Name",
      dataIndex: "reportName",
    },
    {
      name: "Excel",
      dataIndex: "excel",
      render: (text, record) => (
        isReportGenerated ? (
          <Button
            variant="text"
            sx={{ color: "blue", fontSize: 12 }}
            onClick={() => handleDownloadExcel(record.reportName)}
          >
            Excel
          </Button>
        ) : null 
      ),
    },
  ];


  const applicationStatusData = [
    {
      srNo: 1,
      reportName: "Dump-Candidate Data",
      status: "",
    },
    {
      srNo: 2,
      reportName: "Registration In-Progress Candidates",
      status: "",
    },
    {
      srNo: 3,
      reportName: "District-wise Preference Count for Confirmed Candidates",
      status: "",
    },
  ];

  const filteredData = applicationStatusData.filter((item) =>
    item.reportName.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography>Generate Reports</Typography>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="search-report-name">
                  Search by Report Name
                </InputLabel>
                <OutlinedInput
                  id="search-report-name"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  label="Search by Report Name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl error={!!courseError} sx={{ width: "100%" }}>
                <Select
                  size="small"
                  onChange={onChangeCourse}
                  value={courseid}
                  defaultValue={0}
                  sx={{ height: "56px" }}
                >
                  <MenuItem value={0} selected>
                    Select Course <span style={{ color: "red" }}>*</span>
                  </MenuItem>
                  {courseList?.all
                    ?.filter((course) => course.isactive === "1")
                    .map((course, i) => (
                      <MenuItem key={i} value={course.courseid}>
                        {course.coursename}
                      </MenuItem>
                    ))}
                </Select>
                {courseError && (
                  <Typography variant="caption" color="error">
                    {courseError}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} mt={1}>
              <Box sx={{ marginTop: { xs: 2, sm: 0 } }}>
                <BluePrimaryButton
                  title={"Generate Report"}
                  handleSubmit={handleGenerate}
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ marginTop: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "black",
                fontSize: "20px",
                lineHeight: "24px",
                mb: 1,
                backgroundColor: "whitesmoke",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Reports List{" "}
            </Typography>
          </Box>

          <CommonTable
            columnData={applicationStatusColumns}
            tableData={filteredData}
          />
        </Card>
      </Box>
    </>
  );
}

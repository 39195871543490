import React, { useEffect, useState } from "react";
import { itemsPerPage } from "../../utils/constants";
import DataTable from "../../common/dataTable";
import TablePagination from "../../common/tablePagination";
import { Box } from "@mui/material";
import Searchbar from "../../common/searchbar";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import masterFormsActions from "../../redux/masterForms/actions";
import CreateMasterModal from "./modals/createMasterModal";
import { useForm } from "react-hook-form";
import EditMasterModal from "./modals/editMasterModal";
import commonActions from "../../redux/common/actions";
import { PiPlusBold } from "react-icons/pi";

const tableKeys = [
    {
        name: "SI.NO",
        accessor: 'id',
        sortOption: false
    },
    {
        name: "Equivalent board",
        accessor: 'equivalentboardname',
        sortOption: false
    },
    {
        name: "Board state",
        accessor: 'statename',
        sortOption: false
    },
    {
        name: "Board",
        accessor: 'board',
        sortOption: false
    },
];

export default function BoardMaster() {

    const dispatch = useDispatch();

    const { boardList } = useSelector(state => state.masterFormsReducer);

    const { backofficeTokenDetails } = useSelector(state => state.authReducer);

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const { register, handleSubmit, formState: { errors }, reset, control,getValues,trigger } = useForm({mode:'onChange',reValidateMode: 'onChange',defaultValues: {}});

    useEffect(() => {
        dispatch({ type: masterFormsActions.GET_EQUIVALENTBOARD });
        dispatch({ type: masterFormsActions.GET_BOARD });
        dispatch({ type: masterFormsActions.GET_STATE });
        //eslint-disable-next-line
    }, []);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = boardList?.filtered?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(boardList?.filtered?.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        const newOffset = (value - 1) * itemsPerPage;
        setItemOffset(newOffset);
        setCurrentPage(value);
    };

    const handleCreate = (data) => {
        data['equivalentboardid'] = parseInt(data.equivalentboardid);
        data['boardstateid'] = parseInt(data.stateid);
        data['board'] = data.name;
        data['orderno'] = data.orderno;

        data['userid'] = backofficeTokenDetails?.id;
        dispatch({ type: masterFormsActions.CREATE_BOARD, payload: { data: data, resetForm: resetForm } });
    };

    const resetForm = () => {
        reset({
            name: "",
            equivalentboardid: "",
            stateid: "",
        });
    };

    const handleSearch = (e) => {
        let filtered = [];
        setCurrentPage(1);
        setItemOffset(0);
        if (e.target.value) {
            //eslint-disable-next-line
            filtered = boardList?.all?.filter(val => {
                if (val?.board?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                    val?.equivalentboardname?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                    val?.statename?.toLowerCase().includes(e.target.value?.toLowerCase())) {
                    return val;
                }
            });
        } else {
            filtered = [...boardList?.all];
        }
        dispatch({ type: masterFormsActions.SET_BOARD, payload: { ...boardList, filtered: filtered } })
    };

    const handleMasteredit = (page, value) => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: true, type: page, method: 'U', data: null } });
        reset({ name: value?.board, orderno: value?.orderno, equivalentboardid: value?.equivalentboardid, stateid: value?.boardstateid, boardstateid: value?.boardstateid, userid: value?.userid, boardid: value?.boardid });
    };

    const handleUpdate = (data) => {
        data['equivalentboardid'] = parseInt(data.equivalentboardid);
        data['boardstateid'] = parseInt(data.stateid);
        data['board'] = data.name;
        data['orderno'] = parseInt(data.orderno);

        data['userid'] = backofficeTokenDetails?.id;
        dispatch({ type: masterFormsActions.UPDATE_BOARD, payload: { id: data?.boardid, data: data, resetForm: resetForm } });
    };

    const handleshowModal = () => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: true, type: 'board', method: 'C', data: null } });
    };

    return (
        <>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '24px', marginBottom: '14px' }}>
                {/* <Box></Box> */}
                <Searchbar height={48} handleSearch={handleSearch} />
                <MauvelousPrimaryButton title={'Add'} icon={<PiPlusBold />} handleClick={handleshowModal} />
            </Box>
            <Box sx={{ height: 'calc(100vh - 280px)' }}>
                <DataTable
                    keys={tableKeys}
                    values={currentItems}
                    page={'board'}
                    handleMasteredit={handleMasteredit}
                />
            </Box>
            <Box sx={{ position: 'fixed', bottom: 0, right: '24px',padding:'15px 0', width: '100%', py: 1 }}>
                <TablePagination

                    pageCount={pageCount}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </Box>

            <CreateMasterModal
                register={register}
                handleSubmit={handleSubmit} getValues={getValues} trigger={trigger}
                errors={errors}
                handleCreate={handleCreate}
                resetForm={resetForm}
                control={control}
            />
            <EditMasterModal
                register={register}
                handleSubmit={handleSubmit} getValues={getValues} trigger={trigger}
                errors={errors}
                handleUpdate={handleUpdate}
                resetForm={resetForm}
                control={control}
            />
        </>
    )
}
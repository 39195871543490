import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import Layout from "../../layouts/layout";
import Index from "../searchCandidate";


export default function SearchCandidate() {
  return (
    <>
      <Sidebar />
      <Header />
      <Layout>
        <Index />
      </Layout>
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../protectedRoute/PrivateRoute";
import PublicRoute from "../protectedRoute/publicRoute";
import UserList from "../pages/userList";
import MasterList from "../pages/masterList";
import CreateUser from "../pages/createUser";
import UpdateUser from "../pages/updateUser";
import TicketRaiserDetails from "../pages/ticketRaiserDetails";
import HelpDeskLandingPage from "../pages/helpdeskLandingPage";
import Login from "../pages/login";
import MasterDashboard from "../pages/masterDashboard";
import MasterFormsList from "../pages/masterFormsList";
import MasterForm from "../pages/masterForm";
import Home from "../pages/home";
import TicketList from "../pages/ticketList";
// import RaiseTicket from "../pages/raiseTicket";
import GrievanceOnCallList from "../pages/grievanceOnCallList";
// import CandidateDetailsForm from "../pages/candidateDetailsForm";
import ForgetPassword from "../pages/forgetPassword";
import RoleSelection from "../components/roleSelection";
import RaiseTicket from "../pages/raiseTicket";
import CommunicationManagement from "../pages/communicationManagement";
import CourseManagement from "../pages/courseManagement";
import BackOfficeDashboard from "../pages/BackOfficeDashboard";
import AllocateVenue from "../pages/AllocateVenue";
import ForcePush from "../pages/ForcePush";
import RollNoManagement from "../pages/RollNoManagement";
import ObjectionTrackerView from "../pages/objectionTrackerView";
// import CreateProject from "../pages/createProject";
// import ProjectList from "../pages/projectList";
import CreateMaster from "../pages/createMaster";
import ViewObjection from "../components/ObjectionTrackerView/ViewObjection";
import BulkUploadCandidate from "../pages/BulkUploadCandidate";
import AllocateVenueDashboard from "../components/AllocateVenue/AllocateVenueDashboard";

import NotFound from "../common/notfound";
import Index from "../components/checkPaymentHistory";
import CheckApplicationStatus from "../components/checkApplicationStatus/checkApplicationStatus";
import SearchCandidate from "../components/searchCandidate/searchCandidate";
import PaymentReceipt from "../components/checkPaymentHistory/paymentReceipt";
import Generatereports from "../components/GenerateReports/generateReport";
import DatewiseRegReport from "../components/DatewiseReports/datewiseReport";
import PrintApplicationForm from "../components/searchCandidate/printApplicationForm";
import ConsolidateReport from "../components/ConsolidatedReport/consolidatedReport";
import GenderwiseReport from "../components/GenderwiseReport/genderwiseReport";
import CandidateManagement from "../pages/candidateManagement";


const ProjectRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path=""
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/role-selection"
          element={
            <PublicRoute>
              <RoleSelection />
            </PublicRoute>
          }
        />
        <Route
          path="userlogin"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <PublicRoute>
              <ForgetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="master-dashboard"
          element={
            <PrivateRoute>
              <MasterDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="master-forms-list"
          element={
            <PrivateRoute>
              <MasterFormsList />
            </PrivateRoute>
          }
        />
        <Route
          path="master-forms"
          element={
            <PrivateRoute>
              <MasterForm />
            </PrivateRoute>
          }
        />
        <Route
          path="help-desk"
          element={
            <PrivateRoute>
              <HelpDeskLandingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="users"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
        <Route path="master-list" element={<MasterList />} />
        {/* <Route path="create-user" element={<PrivateRoute><CreateUser /></PrivateRoute>} /> */}
        <Route
          path="update-user"
          element={
            <PrivateRoute>
              <UpdateUser />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket-list"
          element={
            <PrivateRoute>
              <TicketList />
            </PrivateRoute>
          }
        />
        <Route
          path="ticket-details"
          element={
            <PrivateRoute>
              <TicketRaiserDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="raise-ticket"
          element={
            <PrivateRoute>
              <RaiseTicket />
            </PrivateRoute>
          }
        />
        <Route
          path="helpdesk-ticket-list"
          element={
            <PrivateRoute>
              <GrievanceOnCallList />
            </PrivateRoute>
          }
        />
        {/* <Route path="create-project" element={<CreateProject />} /> */}
        <Route path="create-master" element={<CreateMaster />} />
        {/* <Route path="project-list" element={<ProjectList/>} /> */}
        {/* <Route path="candidate-details" element={<PrivateRoute><CandidateDetailsForm /></PrivateRoute>} /> */}
        <Route
          path="communication-management"
          element={
            <PrivateRoute>
              <CommunicationManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="course-management"
          element={
            <PrivateRoute>
              <CourseManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="view-objections"
          element={
            <PrivateRoute>
              <ObjectionTrackerView />
            </PrivateRoute>
          }
        />
        <Route
          path="view-single-objection"
          element={
            <PrivateRoute>
              <ViewObjection />
            </PrivateRoute>
          }
        />
        <Route
          path="backoffice-dashboard"
          element={
            <PrivateRoute>
              <BackOfficeDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="allocate-venue"
          element={
            <PrivateRoute>
              <AllocateVenue />
            </PrivateRoute>
          }
        />
        <Route
          path="allocate-venue-dashboard"
          element={
            <PrivateRoute>
              <AllocateVenueDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="force-push"
          element={
            <PrivateRoute>
              <ForcePush />
            </PrivateRoute>
          }
        />
        <Route
          path="roll-no-management"
          element={
            <PrivateRoute>
              <RollNoManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="bulkupload-candidates"
          element={
            <PrivateRoute>
              <BulkUploadCandidate />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
    
       
        {/* <Route path="customer" element={<CustomerDashboard />} /> */}

        <Route path="check-payment-history" element={<Index />} />
        <Route path="FeeProcess/Receipt/OnlinePaymentReceipt" element={<PrivateRoute><PaymentReceipt /></PrivateRoute>} />
        <Route path="check-application-status" element={<CheckApplicationStatus />} />
        <Route path="search-candidate" element={<SearchCandidate />} />
        <Route path="PrintApplicationForm" element={<PrivateRoute><PrintApplicationForm /></PrivateRoute>} />

        <Route path="generate-report" element={<Generatereports />} />
        <Route path="datewise-reg-report" element={<DatewiseRegReport />} />
        <Route path="consolidate-report" element={<ConsolidateReport />} />
        <Route path="gender&categeory-report" element={<GenderwiseReport />} />




        
        




        <Route
          path="candidate-management"
          element={
            <PrivateRoute>
              <CandidateManagement />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default ProjectRoutes;
